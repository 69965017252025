/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ShowClockingSettingsResponse,
  ShowCompanySettingsResponse,
  ShowLeaveSettingsResponse,
  ShowNotificationSettingsResponse,
  ShowScheduleSettingsResponse,
  UpdateClockingSettingsData,
  UpdateClockingSettingsResponse,
  UpdateCompanySettingsData,
  UpdateCompanySettingsResponse,
  UpdateLeaveSettingsData,
  UpdateLeaveSettingsResponse,
  UpdateNotificationSettingsData,
  UpdateNotificationSettingsResponse,
  UpdateScheduleSettingsData,
  UpdateScheduleSettingsResponse,
} from '../models/index';
import {
    ShowClockingSettingsResponseFromJSON,
    ShowClockingSettingsResponseToJSON,
    ShowCompanySettingsResponseFromJSON,
    ShowCompanySettingsResponseToJSON,
    ShowLeaveSettingsResponseFromJSON,
    ShowLeaveSettingsResponseToJSON,
    ShowNotificationSettingsResponseFromJSON,
    ShowNotificationSettingsResponseToJSON,
    ShowScheduleSettingsResponseFromJSON,
    ShowScheduleSettingsResponseToJSON,
    UpdateClockingSettingsDataFromJSON,
    UpdateClockingSettingsDataToJSON,
    UpdateClockingSettingsResponseFromJSON,
    UpdateClockingSettingsResponseToJSON,
    UpdateCompanySettingsDataFromJSON,
    UpdateCompanySettingsDataToJSON,
    UpdateCompanySettingsResponseFromJSON,
    UpdateCompanySettingsResponseToJSON,
    UpdateLeaveSettingsDataFromJSON,
    UpdateLeaveSettingsDataToJSON,
    UpdateLeaveSettingsResponseFromJSON,
    UpdateLeaveSettingsResponseToJSON,
    UpdateNotificationSettingsDataFromJSON,
    UpdateNotificationSettingsDataToJSON,
    UpdateNotificationSettingsResponseFromJSON,
    UpdateNotificationSettingsResponseToJSON,
    UpdateScheduleSettingsDataFromJSON,
    UpdateScheduleSettingsDataToJSON,
    UpdateScheduleSettingsResponseFromJSON,
    UpdateScheduleSettingsResponseToJSON,
} from '../models/index';

export interface ShowClockingSettingsRequest {
    _with?: Array<string>;
}

export interface ShowCompanySettingsRequest {
    _with?: Array<string>;
}

export interface ShowNotificationSettingsRequest {
    _with?: Array<string>;
}

export interface UpdateClockingSettingsRequest {
    _with?: Array<string>;
    updateClockingSettingsData?: UpdateClockingSettingsData;
}

export interface UpdateCompanySettingsRequest {
    _with?: Array<string>;
    updateCompanySettingsData?: UpdateCompanySettingsData;
}

export interface UpdateLeaveSettingsRequest {
    _with?: Array<string>;
    updateLeaveSettingsData?: UpdateLeaveSettingsData;
}

export interface UpdateNotificationSettingsRequest {
    _with?: Array<string>;
    updateNotificationSettingsData?: UpdateNotificationSettingsData;
}

export interface UpdateScheduleSettingsRequest {
    _with?: Array<string>;
    updateScheduleSettingsData?: UpdateScheduleSettingsData;
}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     * Show Clocking Settings
     */
    async showClockingSettingsRaw(requestParameters: ShowClockingSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowClockingSettingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clocking-settings.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/clocking`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowClockingSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Show Clocking Settings
     */
    async showClockingSettings(requestParameters: ShowClockingSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowClockingSettingsResponse> {
        const response = await this.showClockingSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Shows the configured company settings
     * Show Company Settings
     */
    async showCompanySettingsRaw(requestParameters: ShowCompanySettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowCompanySettingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company-settings.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowCompanySettingsResponseFromJSON(jsonValue));
    }

    /**
     * Shows the configured company settings
     * Show Company Settings
     */
    async showCompanySettings(requestParameters: ShowCompanySettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowCompanySettingsResponse> {
        const response = await this.showCompanySettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Leave Settings
     */
    async showLeaveSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowLeaveSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-settings.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/leave`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowLeaveSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Show Leave Settings
     */
    async showLeaveSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowLeaveSettingsResponse> {
        const response = await this.showLeaveSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show the companies notification settings
     * Show Notification Settings
     */
    async showNotificationSettingsRaw(requestParameters: ShowNotificationSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowNotificationSettingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.notification-settings.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/notification`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowNotificationSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Show the companies notification settings
     * Show Notification Settings
     */
    async showNotificationSettings(requestParameters: ShowNotificationSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowNotificationSettingsResponse> {
        const response = await this.showNotificationSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Schedule Settings
     */
    async showScheduleSettingsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowScheduleSettingsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-settings.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/schedule`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowScheduleSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Show Schedule Settings
     */
    async showScheduleSettings(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowScheduleSettingsResponse> {
        const response = await this.showScheduleSettingsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update Clocking Settings
     */
    async updateClockingSettingsRaw(requestParameters: UpdateClockingSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateClockingSettingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clocking-settings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/clocking`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClockingSettingsDataToJSON(requestParameters.updateClockingSettingsData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateClockingSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Update Clocking Settings
     */
    async updateClockingSettings(requestParameters: UpdateClockingSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateClockingSettingsResponse> {
        const response = await this.updateClockingSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a company\'s settings
     * Update Company Settings
     */
    async updateCompanySettingsRaw(requestParameters: UpdateCompanySettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCompanySettingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company-settings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/company`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanySettingsDataToJSON(requestParameters.updateCompanySettingsData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCompanySettingsResponseFromJSON(jsonValue));
    }

    /**
     * Update a company\'s settings
     * Update Company Settings
     */
    async updateCompanySettings(requestParameters: UpdateCompanySettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCompanySettingsResponse> {
        const response = await this.updateCompanySettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Leave Settings
     */
    async updateLeaveSettingsRaw(requestParameters: UpdateLeaveSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateLeaveSettingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.leave-settings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/leave`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateLeaveSettingsDataToJSON(requestParameters.updateLeaveSettingsData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateLeaveSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Update Leave Settings
     */
    async updateLeaveSettings(requestParameters: UpdateLeaveSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateLeaveSettingsResponse> {
        const response = await this.updateLeaveSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the companies notification settings
     * Update Notification Settings
     */
    async updateNotificationSettingsRaw(requestParameters: UpdateNotificationSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateNotificationSettingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.notification-settings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/notification`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateNotificationSettingsDataToJSON(requestParameters.updateNotificationSettingsData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateNotificationSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Update the companies notification settings
     * Update Notification Settings
     */
    async updateNotificationSettings(requestParameters: UpdateNotificationSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateNotificationSettingsResponse> {
        const response = await this.updateNotificationSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Schedule Settings
     */
    async updateScheduleSettingsRaw(requestParameters: UpdateScheduleSettingsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateScheduleSettingsResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.schedule-settings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/schedule`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateScheduleSettingsDataToJSON(requestParameters.updateScheduleSettingsData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateScheduleSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Update Schedule Settings
     */
    async updateScheduleSettings(requestParameters: UpdateScheduleSettingsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateScheduleSettingsResponse> {
        const response = await this.updateScheduleSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
