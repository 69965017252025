/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Credit } from './Credit';
import {
    CreditFromJSON,
    CreditFromJSONTyped,
    CreditToJSON,
    CreditToJSONTyped,
} from './Credit';

/**
 * 
 * @export
 * @interface ShowCreditLineResponse
 */
export interface ShowCreditLineResponse {
    /**
     * 
     * @type {Credit}
     * @memberof ShowCreditLineResponse
     */
    data: Credit;
}

/**
 * Check if a given object implements the ShowCreditLineResponse interface.
 */
export function instanceOfShowCreditLineResponse(value: object): value is ShowCreditLineResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowCreditLineResponseFromJSON(json: any): ShowCreditLineResponse {
    return ShowCreditLineResponseFromJSONTyped(json, false);
}

export function ShowCreditLineResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowCreditLineResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CreditFromJSON(json['data']),
    };
}

  export function ShowCreditLineResponseToJSON(json: any): ShowCreditLineResponse {
      return ShowCreditLineResponseToJSONTyped(json, false);
  }

  export function ShowCreditLineResponseToJSONTyped(value?: ShowCreditLineResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CreditToJSON(value['data']),
    };
}

