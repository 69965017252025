/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublishShiftsData
 */
export interface PublishShiftsData {
    /**
     * 
     * @type {Array<number>}
     * @memberof PublishShiftsData
     */
    shiftIds: Array<number>;
}

/**
 * Check if a given object implements the PublishShiftsData interface.
 */
export function instanceOfPublishShiftsData(value: object): value is PublishShiftsData {
    if (!('shiftIds' in value) || value['shiftIds'] === undefined) return false;
    return true;
}

export function PublishShiftsDataFromJSON(json: any): PublishShiftsData {
    return PublishShiftsDataFromJSONTyped(json, false);
}

export function PublishShiftsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishShiftsData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftIds': json['shiftIds'],
    };
}

  export function PublishShiftsDataToJSON(json: any): PublishShiftsData {
      return PublishShiftsDataToJSONTyped(json, false);
  }

  export function PublishShiftsDataToJSONTyped(value?: PublishShiftsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shiftIds': value['shiftIds'],
    };
}

