/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeaveSettings
 */
export interface LeaveSettings {
    /**
     * 
     * @type {number}
     * @memberof LeaveSettings
     */
    id: number;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveSettings
     */
    enableLeaveRequests: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveSettings
     */
    employeesCanLogOwnUnavailability: boolean;
    /**
     * 
     * @type {number}
     * @memberof LeaveSettings
     */
    defaultLeaveApproverId: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaveSettings
     */
    unavailabilityNoticePeriod: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaveSettings
     */
    defaultEntitlement: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaveSettings
     */
    carryOver: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaveSettings
     */
    negativeCarryOver: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaveSettings
     */
    carryOverWindow: number | null;
}

/**
 * Check if a given object implements the LeaveSettings interface.
 */
export function instanceOfLeaveSettings(value: object): value is LeaveSettings {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('enableLeaveRequests' in value) || value['enableLeaveRequests'] === undefined) return false;
    if (!('employeesCanLogOwnUnavailability' in value) || value['employeesCanLogOwnUnavailability'] === undefined) return false;
    if (!('defaultLeaveApproverId' in value) || value['defaultLeaveApproverId'] === undefined) return false;
    if (!('unavailabilityNoticePeriod' in value) || value['unavailabilityNoticePeriod'] === undefined) return false;
    if (!('defaultEntitlement' in value) || value['defaultEntitlement'] === undefined) return false;
    if (!('carryOver' in value) || value['carryOver'] === undefined) return false;
    if (!('negativeCarryOver' in value) || value['negativeCarryOver'] === undefined) return false;
    if (!('carryOverWindow' in value) || value['carryOverWindow'] === undefined) return false;
    return true;
}

export function LeaveSettingsFromJSON(json: any): LeaveSettings {
    return LeaveSettingsFromJSONTyped(json, false);
}

export function LeaveSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'enableLeaveRequests': json['enableLeaveRequests'],
        'employeesCanLogOwnUnavailability': json['employeesCanLogOwnUnavailability'],
        'defaultLeaveApproverId': json['defaultLeaveApproverId'],
        'unavailabilityNoticePeriod': json['unavailabilityNoticePeriod'],
        'defaultEntitlement': json['defaultEntitlement'],
        'carryOver': json['carryOver'],
        'negativeCarryOver': json['negativeCarryOver'],
        'carryOverWindow': json['carryOverWindow'],
    };
}

  export function LeaveSettingsToJSON(json: any): LeaveSettings {
      return LeaveSettingsToJSONTyped(json, false);
  }

  export function LeaveSettingsToJSONTyped(value?: LeaveSettings | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'enableLeaveRequests': value['enableLeaveRequests'],
        'employeesCanLogOwnUnavailability': value['employeesCanLogOwnUnavailability'],
        'defaultLeaveApproverId': value['defaultLeaveApproverId'],
        'unavailabilityNoticePeriod': value['unavailabilityNoticePeriod'],
        'defaultEntitlement': value['defaultEntitlement'],
        'carryOver': value['carryOver'],
        'negativeCarryOver': value['negativeCarryOver'],
        'carryOverWindow': value['carryOverWindow'],
    };
}

