/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';
import type { ScheduledBreak } from './ScheduledBreak';
import {
    ScheduledBreakFromJSON,
    ScheduledBreakFromJSONTyped,
    ScheduledBreakToJSON,
    ScheduledBreakToJSONTyped,
} from './ScheduledBreak';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    EmployeeToJSONTyped,
} from './Employee';
import type { ShiftConflict } from './ShiftConflict';
import {
    ShiftConflictFromJSON,
    ShiftConflictFromJSONTyped,
    ShiftConflictToJSON,
    ShiftConflictToJSONTyped,
} from './ShiftConflict';
import type { Schedule } from './Schedule';
import {
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
    ScheduleToJSONTyped,
} from './Schedule';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
    TagToJSONTyped,
} from './Tag';
import type { ShiftAreaSession } from './ShiftAreaSession';
import {
    ShiftAreaSessionFromJSON,
    ShiftAreaSessionFromJSONTyped,
    ShiftAreaSessionToJSON,
    ShiftAreaSessionToJSONTyped,
} from './ShiftAreaSession';

/**
 * A time slot that can be assigned to a single Employee
 * @export
 * @interface Shift
 */
export interface Shift {
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    scheduleId: number;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    employeeId: number | null;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    jobRoleId: number;
    /**
     * Whether to offer the shift to all eligible employees
     * @type {boolean}
     * @memberof Shift
     */
    open: boolean;
    /**
     * Whether the system can auto-assign employees when they respond to an open shift
     * @type {boolean}
     * @memberof Shift
     */
    requiresApproval: boolean;
    /**
     * A key that groups together multiple shifts that were generated at the same time
     * @type {string}
     * @memberof Shift
     */
    series: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Shift
     */
    startsAt: Date;
    /**
     * Treated as UTC, and exclusive, eg. 00:00 for midnight rather than 23:59
     * @type {Date}
     * @memberof Shift
     */
    endsAt: Date;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof Shift
     */
    showEndTime: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof Shift
     */
    showJobRole: boolean;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    durationInMinutes: number;
    /**
     * The amount of paid break included in this shift (in minutes)
     * @type {number}
     * @memberof Shift
     */
    paidBreakInMinutes: number;
    /**
     * The amount of paid break included in this shift (in minutes)
     * @type {number}
     * @memberof Shift
     */
    unpaidBreakInMinutes: number;
    /**
     * The amount of working time expected, taking break length into account
     * @type {number}
     * @memberof Shift
     */
    expectedWorkingTimeInMinutes: number;
    /**
     * The cost of staffing this shift. Will be null if a member of staff has not yet been assigned.
     * @type {number}
     * @memberof Shift
     */
    cost: number | null;
    /**
     * 
     * @type {string}
     * @memberof Shift
     */
    notes: string | null;
    /**
     * ID of the employee who created this
     * @type {number}
     * @memberof Shift
     */
    creatorId: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof Shift
     */
    tagIds: Array<number>;
    /**
     * 
     * @type {Array<ScheduledBreak>}
     * @memberof Shift
     */
    scheduledBreaks: Array<ScheduledBreak>;
    /**
     * 
     * @type {Array<ShiftConflict>}
     * @memberof Shift
     */
    shiftConflicts: Array<ShiftConflict>;
    /**
     * 
     * @type {Employee}
     * @memberof Shift
     */
    employee?: Employee;
    /**
     * 
     * @type {JobRole}
     * @memberof Shift
     */
    jobRole?: JobRole;
    /**
     * 
     * @type {Schedule}
     * @memberof Shift
     */
    schedule?: Schedule;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Shift
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {Date}
     * @memberof Shift
     */
    publishedAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Shift
     */
    publishedById: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Shift
     */
    acknowledgedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Shift
     */
    lockedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Shift
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Shift
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Array<ShiftAreaSession>}
     * @memberof Shift
     */
    shiftAreaSessions?: Array<ShiftAreaSession>;
}

/**
 * Check if a given object implements the Shift interface.
 */
export function instanceOfShift(value: object): value is Shift {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('locationId' in value) || value['locationId'] === undefined) return false;
    if (!('scheduleId' in value) || value['scheduleId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('jobRoleId' in value) || value['jobRoleId'] === undefined) return false;
    if (!('open' in value) || value['open'] === undefined) return false;
    if (!('requiresApproval' in value) || value['requiresApproval'] === undefined) return false;
    if (!('series' in value) || value['series'] === undefined) return false;
    if (!('startsAt' in value) || value['startsAt'] === undefined) return false;
    if (!('endsAt' in value) || value['endsAt'] === undefined) return false;
    if (!('showEndTime' in value) || value['showEndTime'] === undefined) return false;
    if (!('showJobRole' in value) || value['showJobRole'] === undefined) return false;
    if (!('durationInMinutes' in value) || value['durationInMinutes'] === undefined) return false;
    if (!('paidBreakInMinutes' in value) || value['paidBreakInMinutes'] === undefined) return false;
    if (!('unpaidBreakInMinutes' in value) || value['unpaidBreakInMinutes'] === undefined) return false;
    if (!('expectedWorkingTimeInMinutes' in value) || value['expectedWorkingTimeInMinutes'] === undefined) return false;
    if (!('cost' in value) || value['cost'] === undefined) return false;
    if (!('notes' in value) || value['notes'] === undefined) return false;
    if (!('creatorId' in value) || value['creatorId'] === undefined) return false;
    if (!('tagIds' in value) || value['tagIds'] === undefined) return false;
    if (!('scheduledBreaks' in value) || value['scheduledBreaks'] === undefined) return false;
    if (!('shiftConflicts' in value) || value['shiftConflicts'] === undefined) return false;
    if (!('publishedAt' in value) || value['publishedAt'] === undefined) return false;
    if (!('publishedById' in value) || value['publishedById'] === undefined) return false;
    if (!('acknowledgedAt' in value) || value['acknowledgedAt'] === undefined) return false;
    if (!('lockedAt' in value) || value['lockedAt'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function ShiftFromJSON(json: any): Shift {
    return ShiftFromJSONTyped(json, false);
}

export function ShiftFromJSONTyped(json: any, ignoreDiscriminator: boolean): Shift {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'locationId': json['locationId'],
        'scheduleId': json['scheduleId'],
        'employeeId': json['employeeId'],
        'jobRoleId': json['jobRoleId'],
        'open': json['open'],
        'requiresApproval': json['requiresApproval'],
        'series': json['series'],
        'startsAt': (new Date(json['startsAt'])),
        'endsAt': (new Date(json['endsAt'])),
        'showEndTime': json['showEndTime'],
        'showJobRole': json['showJobRole'],
        'durationInMinutes': json['durationInMinutes'],
        'paidBreakInMinutes': json['paidBreakInMinutes'],
        'unpaidBreakInMinutes': json['unpaidBreakInMinutes'],
        'expectedWorkingTimeInMinutes': json['expectedWorkingTimeInMinutes'],
        'cost': json['cost'],
        'notes': json['notes'],
        'creatorId': json['creatorId'],
        'tagIds': json['tagIds'],
        'scheduledBreaks': ((json['scheduledBreaks'] as Array<any>).map(ScheduledBreakFromJSON)),
        'shiftConflicts': ((json['shiftConflicts'] as Array<any>).map(ShiftConflictFromJSON)),
        'employee': json['employee'] == null ? undefined : EmployeeFromJSON(json['employee']),
        'jobRole': json['jobRole'] == null ? undefined : JobRoleFromJSON(json['jobRole']),
        'schedule': json['schedule'] == null ? undefined : ScheduleFromJSON(json['schedule']),
        'tags': json['tags'] == null ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'publishedAt': (json['publishedAt'] == null ? null : new Date(json['publishedAt'])),
        'publishedById': json['publishedById'],
        'acknowledgedAt': (json['acknowledgedAt'] == null ? null : new Date(json['acknowledgedAt'])),
        'lockedAt': (json['lockedAt'] == null ? null : new Date(json['lockedAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'shiftAreaSessions': json['shiftAreaSessions'] == null ? undefined : ((json['shiftAreaSessions'] as Array<any>).map(ShiftAreaSessionFromJSON)),
    };
}

  export function ShiftToJSON(json: any): Shift {
      return ShiftToJSONTyped(json, false);
  }

  export function ShiftToJSONTyped(value?: Shift | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'locationId': value['locationId'],
        'scheduleId': value['scheduleId'],
        'employeeId': value['employeeId'],
        'jobRoleId': value['jobRoleId'],
        'open': value['open'],
        'requiresApproval': value['requiresApproval'],
        'series': value['series'],
        'startsAt': ((value['startsAt']).toISOString()),
        'endsAt': ((value['endsAt']).toISOString()),
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'durationInMinutes': value['durationInMinutes'],
        'paidBreakInMinutes': value['paidBreakInMinutes'],
        'unpaidBreakInMinutes': value['unpaidBreakInMinutes'],
        'expectedWorkingTimeInMinutes': value['expectedWorkingTimeInMinutes'],
        'cost': value['cost'],
        'notes': value['notes'],
        'creatorId': value['creatorId'],
        'tagIds': value['tagIds'],
        'scheduledBreaks': ((value['scheduledBreaks'] as Array<any>).map(ScheduledBreakToJSON)),
        'shiftConflicts': ((value['shiftConflicts'] as Array<any>).map(ShiftConflictToJSON)),
        'employee': EmployeeToJSON(value['employee']),
        'jobRole': JobRoleToJSON(value['jobRole']),
        'schedule': ScheduleToJSON(value['schedule']),
        'tags': value['tags'] == null ? undefined : ((value['tags'] as Array<any>).map(TagToJSON)),
        'publishedAt': (value['publishedAt'] == null ? null : (value['publishedAt'] as any).toISOString()),
        'publishedById': value['publishedById'],
        'acknowledgedAt': (value['acknowledgedAt'] == null ? null : (value['acknowledgedAt'] as any).toISOString()),
        'lockedAt': (value['lockedAt'] == null ? null : (value['lockedAt'] as any).toISOString()),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'shiftAreaSessions': value['shiftAreaSessions'] == null ? undefined : ((value['shiftAreaSessions'] as Array<any>).map(ShiftAreaSessionToJSON)),
    };
}

