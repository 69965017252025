/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateSetupIntentResponse
 */
export interface CreateSetupIntentResponse {
    /**
     * The client secret for the setup intent
     * @type {string}
     * @memberof CreateSetupIntentResponse
     */
    data: string;
}

/**
 * Check if a given object implements the CreateSetupIntentResponse interface.
 */
export function instanceOfCreateSetupIntentResponse(value: object): value is CreateSetupIntentResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateSetupIntentResponseFromJSON(json: any): CreateSetupIntentResponse {
    return CreateSetupIntentResponseFromJSONTyped(json, false);
}

export function CreateSetupIntentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateSetupIntentResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
    };
}

  export function CreateSetupIntentResponseToJSON(json: any): CreateSetupIntentResponse {
      return CreateSetupIntentResponseToJSONTyped(json, false);
  }

  export function CreateSetupIntentResponseToJSONTyped(value?: CreateSetupIntentResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': value['data'],
    };
}

