import { LogoutReason } from '../auth/logoutReason';
import { redirect } from '../../router/router';
import { routes } from '../../router/routes';

class Controller {
  controller!: AbortController;

  constructor() {
    this.init();
  }

  init() {
    this.controller = new AbortController();
    this.controller.signal.onabort = () => this.init();
  }

  get signal() {
    return this.controller.signal;
  }

  abort() {
    this.controller.abort();
  }
}

export const controller = new Controller();

export const fetchAbort = (
  input: RequestInfo,
  init?: RequestInit,
): Promise<Response> | null => {
  if (init) {
    const { signal } = controller;
    init.signal = signal;
  }

  return fetch(input, init);
};

export const abortAndLogout = () => {
  // Abort any in-flight / pending requests as they'll mostly likely result in more 401 responses.
  controller.abort();

  // Redirect to the logout screen with a generic 'session expired' message
  setTimeout(() => {
    redirect(
      routes.logout.route({
        reason: LogoutReason.NotAuthenticated,
      }),
    ).then();
  }, 300);
};
