/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessToken
 */
export interface AccessToken {
    /**
     * 
     * @type {string}
     * @memberof AccessToken
     */
    token: string;
}

/**
 * Check if a given object implements the AccessToken interface.
 */
export function instanceOfAccessToken(value: object): value is AccessToken {
    if (!('token' in value) || value['token'] === undefined) return false;
    return true;
}

export function AccessTokenFromJSON(json: any): AccessToken {
    return AccessTokenFromJSONTyped(json, false);
}

export function AccessTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessToken {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

  export function AccessTokenToJSON(json: any): AccessToken {
      return AccessTokenToJSONTyped(json, false);
  }

  export function AccessTokenToJSONTyped(value?: AccessToken | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token': value['token'],
    };
}

