import { Content, toast, ToastOptions } from 'vue3-toastify';

export enum ToastTypeEnum {
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}

const defaultOptions = {
  pauseOnFocusLoss: false,
};

export const showInfoToast = (c: Content, o: ToastOptions = {}) =>
  toast(c, {
    type: ToastTypeEnum.Info,
    ...defaultOptions,
    ...o,
  });

export const showWarningToast = (c: Content, o: ToastOptions = {}) =>
  toast(c, {
    type: ToastTypeEnum.Warning,
    ...defaultOptions,
    ...o,
  });

export const showErrorToast = (c: Content, o: ToastOptions = {}) =>
  toast(c, {
    type: ToastTypeEnum.Error,
    ...defaultOptions,
    ...o,
  });
