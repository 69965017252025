/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Shift } from './Shift';
import {
    ShiftFromJSON,
    ShiftFromJSONTyped,
    ShiftToJSON,
    ShiftToJSONTyped,
} from './Shift';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListShiftsResponse
 */
export interface ListShiftsResponse {
    /**
     * 
     * @type {Array<Shift>}
     * @memberof ListShiftsResponse
     */
    data: Array<Shift>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListShiftsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListShiftsResponse interface.
 */
export function instanceOfListShiftsResponse(value: object): value is ListShiftsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListShiftsResponseFromJSON(json: any): ListShiftsResponse {
    return ListShiftsResponseFromJSONTyped(json, false);
}

export function ListShiftsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListShiftsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ShiftFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListShiftsResponseToJSON(json: any): ListShiftsResponse {
      return ListShiftsResponseToJSONTyped(json, false);
  }

  export function ListShiftsResponseToJSONTyped(value?: ListShiftsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ShiftToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

