/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanyOnboarding } from './CompanyOnboarding';
import {
    CompanyOnboardingFromJSON,
    CompanyOnboardingFromJSONTyped,
    CompanyOnboardingToJSON,
    CompanyOnboardingToJSONTyped,
} from './CompanyOnboarding';

/**
 * 
 * @export
 * @interface ShowCompanyOnboardingResponse
 */
export interface ShowCompanyOnboardingResponse {
    /**
     * 
     * @type {CompanyOnboarding}
     * @memberof ShowCompanyOnboardingResponse
     */
    data: CompanyOnboarding;
}

/**
 * Check if a given object implements the ShowCompanyOnboardingResponse interface.
 */
export function instanceOfShowCompanyOnboardingResponse(value: object): value is ShowCompanyOnboardingResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowCompanyOnboardingResponseFromJSON(json: any): ShowCompanyOnboardingResponse {
    return ShowCompanyOnboardingResponseFromJSONTyped(json, false);
}

export function ShowCompanyOnboardingResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowCompanyOnboardingResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanyOnboardingFromJSON(json['data']),
    };
}

  export function ShowCompanyOnboardingResponseToJSON(json: any): ShowCompanyOnboardingResponse {
      return ShowCompanyOnboardingResponseToJSONTyped(json, false);
  }

  export function ShowCompanyOnboardingResponseToJSONTyped(value?: ShowCompanyOnboardingResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CompanyOnboardingToJSON(value['data']),
    };
}

