/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AuthenticationData } from './AuthenticationData';
import {
    AuthenticationDataFromJSON,
    AuthenticationDataFromJSONTyped,
    AuthenticationDataToJSON,
    AuthenticationDataToJSONTyped,
} from './AuthenticationData';

/**
 * 
 * @export
 * @interface RefreshAccessTokenResponse
 */
export interface RefreshAccessTokenResponse {
    /**
     * 
     * @type {AuthenticationData}
     * @memberof RefreshAccessTokenResponse
     */
    data: AuthenticationData;
}

/**
 * Check if a given object implements the RefreshAccessTokenResponse interface.
 */
export function instanceOfRefreshAccessTokenResponse(value: object): value is RefreshAccessTokenResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function RefreshAccessTokenResponseFromJSON(json: any): RefreshAccessTokenResponse {
    return RefreshAccessTokenResponseFromJSONTyped(json, false);
}

export function RefreshAccessTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshAccessTokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': AuthenticationDataFromJSON(json['data']),
    };
}

  export function RefreshAccessTokenResponseToJSON(json: any): RefreshAccessTokenResponse {
      return RefreshAccessTokenResponseToJSONTyped(json, false);
  }

  export function RefreshAccessTokenResponseToJSONTyped(value?: RefreshAccessTokenResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': AuthenticationDataToJSON(value['data']),
    };
}

