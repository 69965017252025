/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { AccessRolesPermission } from './AccessRolesPermission';
import {
    AccessRolesPermissionFromJSON,
    AccessRolesPermissionFromJSONTyped,
    AccessRolesPermissionToJSON,
    AccessRolesPermissionToJSONTyped,
} from './AccessRolesPermission';

/**
 * Defines a set of permissions an employee can be given
 * @export
 * @interface AccessRole
 */
export interface AccessRole {
    /**
     * 
     * @type {number}
     * @memberof AccessRole
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof AccessRole
     */
    name: string;
    /**
     * If null, the role is managed by Shiftie
     * @type {number}
     * @memberof AccessRole
     */
    companyId: number | null;
    /**
     * 
     * @type {Date}
     * @memberof AccessRole
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof AccessRole
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Array<AccessRolesPermission>}
     * @memberof AccessRole
     */
    permissions?: Array<AccessRolesPermission>;
}

/**
 * Check if a given object implements the AccessRole interface.
 */
export function instanceOfAccessRole(value: object): value is AccessRole {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function AccessRoleFromJSON(json: any): AccessRole {
    return AccessRoleFromJSONTyped(json, false);
}

export function AccessRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'companyId': json['companyId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'permissions': json['permissions'] == null ? undefined : ((json['permissions'] as Array<any>).map(AccessRolesPermissionFromJSON)),
    };
}

  export function AccessRoleToJSON(json: any): AccessRole {
      return AccessRoleToJSONTyped(json, false);
  }

  export function AccessRoleToJSONTyped(value?: AccessRole | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'companyId': value['companyId'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'permissions': value['permissions'] == null ? undefined : ((value['permissions'] as Array<any>).map(AccessRolesPermissionToJSON)),
    };
}

