import { authApiFactory, v1ApiFactory } from './lib/api/factory';
import { AuthenticationApi, UserApi } from './sdk/auth';
import {
  addUriHeader,
  includeCredentials,
  redirectIfUnauthenticated,
} from './lib/api/middleware';
import {
  BillingApi,
  CacheMeta,
  ClockingApi,
  ClockInPortalApi,
  CompanyApi,
  EmployeeApi,
  JobRoleApi,
  LocationApi,
  Pagination,
  SettingsApi,
  ShiftApi,
  TimesheetApi,
} from './sdk/v1';

export interface GenericApiResponse<T> {
  data: Array<T>;
  pagination: Pagination;
  cache?: CacheMeta;
}

/**
 * Allows you to fetch all the results for a paginated API response (which is any list action!). Only use this when
 * you have a finite set of results (eg. list of countries, timezones, currencies, etc). Responses that could grow
 * over time (usually those that return user-data) should be handled via other approaches such as lazy-loading,
 * pagination controls, etc.
 *
 * To use this function replace:
 *   somethingApi.listSomething(params)
 *   ...with...
 *   fetchAll(somethingApi.listSomething.bind(somethingApi), params)
 */
export const fetchAll = async <T, P>(
  // @ts-ignore
  apiMethod: (query) => Promise<GenericApiResponse<T>>,
  params: P | object = {},
  page: number = 1,
): Promise<GenericApiResponse<T>> => {
  let results: GenericApiResponse<T>['data'] = [];
  const { data, pagination, cache } = await apiMethod({ ...params, page });
  results = results.concat(data);

  if (pagination.lastPage > pagination.currentPage) {
    const promises = [];
    for (let i = pagination.currentPage + 1; i <= pagination.lastPage; i += 1) {
      promises.push(apiMethod({ ...params, page: i }));
    }
    const responses = await Promise.all(promises);
    results = results.concat(...responses.map((response) => response.data));
  }

  const fakedPagination: Pagination = {
    from: 1,
    lastPage: 1,
    lastPageUrl: pagination.firstPageUrl,
    nextPageUrl: null,
    path: pagination.path,
    perPage: 0,
    prevPageUrl: null,
    total: results.length,
    currentPage: 1,
    firstPageUrl: pagination.firstPageUrl,
    to: results.length,
  };

  return {
    data: results,
    pagination: fakedPagination,
    ...(cache && { cache }),
  };
};

authApiFactory.registerCustomDefaultConfiguration(
  AuthenticationApi,
  authApiFactory.makeConfigurationParameters({
    middleware: [
      authApiFactory.createMiddleware(includeCredentials),
      authApiFactory.createMiddleware(redirectIfUnauthenticated),
      authApiFactory.createMiddleware(addUriHeader),
    ],
  }),
);

export const authApi = authApiFactory.prepare(AuthenticationApi);
export const usersApi = authApiFactory.prepare(UserApi);

export const billingApi = v1ApiFactory.create(BillingApi);
export const clockingApi = v1ApiFactory.create(ClockingApi);
export const companyApi = v1ApiFactory.create(CompanyApi);
export const employeeApi = v1ApiFactory.create(EmployeeApi);
export const jobRoleApi = v1ApiFactory.create(JobRoleApi);
export const locationApi = v1ApiFactory.create(LocationApi);
export const clockInPortalApi = v1ApiFactory.create(ClockInPortalApi);
export const settingsApi = v1ApiFactory.create(SettingsApi);
export const shiftApi = v1ApiFactory.create(ShiftApi);
export const timesheetApi = v1ApiFactory.create(TimesheetApi);
