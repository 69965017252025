/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Assign a user to a company, supplying optional extra data.
 * @export
 * @interface CreateEmployeeData
 */
export interface CreateEmployeeData {
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeData
     */
    workPatternId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeData
     */
    defaultJobRoleId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    medicalDetails?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeData
     */
    leavePolicyId?: number | null;
    /**
     * The number of hours this employee would usually work in a week
     * @type {number}
     * @memberof CreateEmployeeData
     */
    workingHoursPerWeek?: number;
    /**
     * The number of hours taken from this employee's leave allowance for a day's worth of leave
     * @type {number}
     * @memberof CreateEmployeeData
     */
    leaveHoursUsedPerDay?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeData
     */
    exemptFromOvertime?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    employmentType?: CreateEmployeeDataEmploymentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    contactEmail?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeData
     */
    leaveApproverId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeData
     */
    lineManagerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateEmployeeData
     */
    baseRate?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    payrollId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    phoneNumber?: string | null;
    /**
     * The ID of a photo stored via the /uploads endpoint
     * @type {number}
     * @memberof CreateEmployeeData
     */
    photoId?: number | null;
    /**
     * Email address. If a user exists with this email address then the userId will be added to the employee - otherwise a new user is created
     * @type {string}
     * @memberof CreateEmployeeData
     */
    email?: string | null;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateEmployeeData
     */
    joinDate?: ShiftiePlainDate | null;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateEmployeeData
     */
    probationDate?: ShiftiePlainDate | null;
    /**
     * 
     * @type {string}
     * @memberof CreateEmployeeData
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateEmployeeData
     */
    canRequestLeave?: boolean;
    /**
     * A boolean to determine whether an email should be sent out. Default to true (Yes, we do ;])
     * @type {boolean}
     * @memberof CreateEmployeeData
     */
    sendConfirmation?: boolean;
    /**
     * A list of attributes/skills that the employee has
     * @type {Array<number>}
     * @memberof CreateEmployeeData
     */
    employeeAttributes?: Array<number>;
    /**
     * A list of locations that the employee belongs to
     * @type {Array<number>}
     * @memberof CreateEmployeeData
     */
    locations: Array<number>;
    /**
     * A list of job roles that the employee belongs to
     * @type {Array<number>}
     * @memberof CreateEmployeeData
     */
    jobRoles: Array<number>;
    /**
     * A list of access roles that the employee should be given. If not supplied, the default managed "Employee" access role will be applied.
     * @type {Array<number>}
     * @memberof CreateEmployeeData
     */
    accessRoles?: Array<number>;
    /**
     * A list of schedules that the employee should be assigned to.
     * @type {Array<number>}
     * @memberof CreateEmployeeData
     */
    schedules?: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum CreateEmployeeDataEmploymentTypeEnum {
    Agency = 'Agency',
    AnnualisedContract = 'Annualised Contract',
    ApprenticeTrainee = 'Apprentice / Trainee',
    Casual = 'Casual',
    FixedTerm = 'Fixed Term',
    FullTime = 'Full Time',
    PartTime = 'Part Time',
    SelfEmployed = 'Self-Employed',
    Temporary = 'Temporary',
    Volunteer = 'Volunteer'
}


/**
 * Check if a given object implements the CreateEmployeeData interface.
 */
export function instanceOfCreateEmployeeData(value: object): value is CreateEmployeeData {
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('locations' in value) || value['locations'] === undefined) return false;
    if (!('jobRoles' in value) || value['jobRoles'] === undefined) return false;
    return true;
}

export function CreateEmployeeDataFromJSON(json: any): CreateEmployeeData {
    return CreateEmployeeDataFromJSONTyped(json, false);
}

export function CreateEmployeeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeData {
    if (json == null) {
        return json;
    }
    return {
        
        'workPatternId': json['workPatternId'] == null ? undefined : json['workPatternId'],
        'defaultJobRoleId': json['defaultJobRoleId'] == null ? undefined : json['defaultJobRoleId'],
        'medicalDetails': json['medicalDetails'] == null ? undefined : json['medicalDetails'],
        'leavePolicyId': json['leavePolicyId'] == null ? undefined : json['leavePolicyId'],
        'workingHoursPerWeek': json['workingHoursPerWeek'] == null ? undefined : json['workingHoursPerWeek'],
        'leaveHoursUsedPerDay': json['leaveHoursUsedPerDay'] == null ? undefined : json['leaveHoursUsedPerDay'],
        'exemptFromOvertime': json['exemptFromOvertime'] == null ? undefined : json['exemptFromOvertime'],
        'employmentType': json['employmentType'] == null ? undefined : json['employmentType'],
        'contactEmail': json['contactEmail'] == null ? undefined : json['contactEmail'],
        'leaveApproverId': json['leaveApproverId'] == null ? undefined : json['leaveApproverId'],
        'lineManagerId': json['lineManagerId'] == null ? undefined : json['lineManagerId'],
        'baseRate': json['baseRate'] == null ? undefined : json['baseRate'],
        'payrollId': json['payrollId'] == null ? undefined : json['payrollId'],
        'gender': json['gender'] == null ? undefined : json['gender'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'phoneNumber': json['phoneNumber'] == null ? undefined : json['phoneNumber'],
        'photoId': json['photoId'] == null ? undefined : json['photoId'],
        'email': json['email'] == null ? undefined : json['email'],
        'joinDate': json['joinDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['joinDate']),
        'probationDate': json['probationDate'] == null ? undefined : ShiftiePlainDateFromJSON(json['probationDate']),
        'jobTitle': json['jobTitle'] == null ? undefined : json['jobTitle'],
        'canRequestLeave': json['canRequestLeave'] == null ? undefined : json['canRequestLeave'],
        'sendConfirmation': json['sendConfirmation'] == null ? undefined : json['sendConfirmation'],
        'employeeAttributes': json['employeeAttributes'] == null ? undefined : json['employeeAttributes'],
        'locations': json['locations'],
        'jobRoles': json['jobRoles'],
        'accessRoles': json['accessRoles'] == null ? undefined : json['accessRoles'],
        'schedules': json['schedules'] == null ? undefined : json['schedules'],
    };
}

  export function CreateEmployeeDataToJSON(json: any): CreateEmployeeData {
      return CreateEmployeeDataToJSONTyped(json, false);
  }

  export function CreateEmployeeDataToJSONTyped(value?: CreateEmployeeData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'workPatternId': value['workPatternId'],
        'defaultJobRoleId': value['defaultJobRoleId'],
        'medicalDetails': value['medicalDetails'],
        'leavePolicyId': value['leavePolicyId'],
        'workingHoursPerWeek': value['workingHoursPerWeek'],
        'leaveHoursUsedPerDay': value['leaveHoursUsedPerDay'],
        'exemptFromOvertime': value['exemptFromOvertime'],
        'employmentType': value['employmentType'],
        'contactEmail': value['contactEmail'],
        'leaveApproverId': value['leaveApproverId'],
        'lineManagerId': value['lineManagerId'],
        'baseRate': value['baseRate'],
        'payrollId': value['payrollId'],
        'gender': value['gender'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'phoneNumber': value['phoneNumber'],
        'photoId': value['photoId'],
        'email': value['email'],
        'joinDate': ShiftiePlainDateToJSON(value['joinDate']),
        'probationDate': ShiftiePlainDateToJSON(value['probationDate']),
        'jobTitle': value['jobTitle'],
        'canRequestLeave': value['canRequestLeave'],
        'sendConfirmation': value['sendConfirmation'],
        'employeeAttributes': value['employeeAttributes'],
        'locations': value['locations'],
        'jobRoles': value['jobRoles'],
        'accessRoles': value['accessRoles'],
        'schedules': value['schedules'],
    };
}

