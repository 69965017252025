/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { TimesheetEntry } from './TimesheetEntry';
import {
    TimesheetEntryFromJSON,
    TimesheetEntryFromJSONTyped,
    TimesheetEntryToJSON,
    TimesheetEntryToJSONTyped,
} from './TimesheetEntry';

/**
 * 
 * @export
 * @interface ListTimesheetEntriesResponse
 */
export interface ListTimesheetEntriesResponse {
    /**
     * 
     * @type {Array<TimesheetEntry>}
     * @memberof ListTimesheetEntriesResponse
     */
    data: Array<TimesheetEntry>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListTimesheetEntriesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListTimesheetEntriesResponse interface.
 */
export function instanceOfListTimesheetEntriesResponse(value: object): value is ListTimesheetEntriesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListTimesheetEntriesResponseFromJSON(json: any): ListTimesheetEntriesResponse {
    return ListTimesheetEntriesResponseFromJSONTyped(json, false);
}

export function ListTimesheetEntriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListTimesheetEntriesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(TimesheetEntryFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListTimesheetEntriesResponseToJSON(json: any): ListTimesheetEntriesResponse {
      return ListTimesheetEntriesResponseToJSONTyped(json, false);
  }

  export function ListTimesheetEntriesResponseToJSONTyped(value?: ListTimesheetEntriesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(TimesheetEntryToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

