/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents the billing contact details for a company
 * @export
 * @interface BillingDetails
 */
export interface BillingDetails {
    /**
     * 
     * @type {number}
     * @memberof BillingDetails
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BillingDetails
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof BillingDetails
     */
    name: string;
    /**
     * A 2 letter country code, must conform to ISO 3316-1 Alpha-2
     * @type {string}
     * @memberof BillingDetails
     */
    countryCode: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetails
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetails
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof BillingDetails
     */
    address2: string | null;
    /**
     * Represents the postal code, any format e.g. EX2 5AZ or 90210
     * @type {string}
     * @memberof BillingDetails
     */
    postalCode: string;
}

/**
 * Check if a given object implements the BillingDetails interface.
 */
export function instanceOfBillingDetails(value: object): value is BillingDetails {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('countryCode' in value) || value['countryCode'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('address1' in value) || value['address1'] === undefined) return false;
    if (!('address2' in value) || value['address2'] === undefined) return false;
    if (!('postalCode' in value) || value['postalCode'] === undefined) return false;
    return true;
}

export function BillingDetailsFromJSON(json: any): BillingDetails {
    return BillingDetailsFromJSONTyped(json, false);
}

export function BillingDetailsFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingDetails {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'countryCode': json['countryCode'],
        'city': json['city'],
        'address1': json['address1'],
        'address2': json['address2'],
        'postalCode': json['postalCode'],
    };
}

  export function BillingDetailsToJSON(json: any): BillingDetails {
      return BillingDetailsToJSONTyped(json, false);
  }

  export function BillingDetailsToJSONTyped(value?: BillingDetails | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'countryCode': value['countryCode'],
        'city': value['city'],
        'address1': value['address1'],
        'address2': value['address2'],
        'postalCode': value['postalCode'],
    };
}

