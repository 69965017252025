/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OffersBehaviour } from './OffersBehaviour';
import {
    OffersBehaviourFromJSON,
    OffersBehaviourFromJSONTyped,
    OffersBehaviourToJSON,
    OffersBehaviourToJSONTyped,
} from './OffersBehaviour';

/**
 * 
 * @export
 * @interface Offer
 */
export interface Offer {
    /**
     * 
     * @type {number}
     * @memberof Offer
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof Offer
     */
    summary: string;
    /**
     * 
     * @type {Date}
     * @memberof Offer
     */
    expiresAt: Date | null;
    /**
     * 
     * @type {Array<OffersBehaviour>}
     * @memberof Offer
     */
    offersBehaviours?: Array<OffersBehaviour>;
}

/**
 * Check if a given object implements the Offer interface.
 */
export function instanceOfOffer(value: object): value is Offer {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('summary' in value) || value['summary'] === undefined) return false;
    if (!('expiresAt' in value) || value['expiresAt'] === undefined) return false;
    return true;
}

export function OfferFromJSON(json: any): Offer {
    return OfferFromJSONTyped(json, false);
}

export function OfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): Offer {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'summary': json['summary'],
        'expiresAt': (json['expiresAt'] == null ? null : new Date(json['expiresAt'])),
        'offersBehaviours': json['offersBehaviours'] == null ? undefined : ((json['offersBehaviours'] as Array<any>).map(OffersBehaviourFromJSON)),
    };
}

  export function OfferToJSON(json: any): Offer {
      return OfferToJSONTyped(json, false);
  }

  export function OfferToJSONTyped(value?: Offer | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'code': value['code'],
        'summary': value['summary'],
        'expiresAt': (value['expiresAt'] == null ? null : (value['expiresAt'] as any).toISOString()),
        'offersBehaviours': value['offersBehaviours'] == null ? undefined : ((value['offersBehaviours'] as Array<any>).map(OffersBehaviourToJSON)),
    };
}

