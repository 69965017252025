export const moveArrayItem = <T extends unknown>(
  data: T[],
  from: number,
  to: number,
): T[] => {
  if (!data.length) {
    return [];
  }
  if (from > data.length || to > data.length) {
    throw new Error('Cannot move an item from or to specified position');
  }
  // Don't affect original array
  const dataCopy = data.slice();
  const movable = dataCopy.splice(from, 1)[0];
  dataCopy.splice(to, 0, movable);
  return dataCopy;
};

/**
 * Sorts an array of objects by a given property. A descending sort can be triggered by prefixing the property name with
 * a dash. Based on Lodash's sortBy function.
 *
 * Possible future todo - support multiple properties (remember to update unit test!)
 */
type SortProp<T> =
  | Exclude<keyof T, symbol | number>
  | `-${Exclude<keyof T, symbol | number>}`
  | string;
export function sortBy<T>(
  arr: Array<T> | any[],
  prop: SortProp<T> | SortProp<T>[],
): Array<T> {
  if (!arr.length) return arr;

  let filterKeys = typeof prop === 'string' ? [prop] : prop;
  const reverseSort = filterKeys[0][0] === '-';
  filterKeys = filterKeys.map((p) => (p[0] === '-' ? p.substring(1) : p));

  const valuesAreString =
    typeof arr[0][
      Object.keys(arr[0]).find((key) => filterKeys.includes(key)) as string
    ] === 'string';
  let comparator: Function;

  if (valuesAreString) {
    // values are strings
    comparator = (a: string, b: string) => a.localeCompare(b);
  } else {
    // We'll assume values are numbers
    comparator = (a: number | Date, b: number | Date) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
  }

  const multiplier = reverseSort ? -1 : 1;

  // Check which of the props keys exists in the object
  return [...arr].sort(
    (a, b) =>
      comparator(
        a[Object.keys(a).find((key) => filterKeys.includes(key)) as string],
        b[Object.keys(b).find((key) => filterKeys.includes(key)) as string],
      ) * multiplier,
  );
}

export const flattenArray = <T extends unknown>(
  arr: Array<Array<T>>,
): Array<T> => arr.reduce((acc, val) => acc.concat(val), []);
