/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftArea } from './ShiftArea';
import {
    ShiftAreaFromJSON,
    ShiftAreaFromJSONTyped,
    ShiftAreaToJSON,
    ShiftAreaToJSONTyped,
} from './ShiftArea';

/**
 * 
 * @export
 * @interface ShiftAreaSession
 */
export interface ShiftAreaSession {
    /**
     * 
     * @type {number}
     * @memberof ShiftAreaSession
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftAreaSession
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftAreaSession
     */
    shiftId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftAreaSession
     */
    shiftAreaId: number;
    /**
     * 
     * @type {Date}
     * @memberof ShiftAreaSession
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof ShiftAreaSession
     */
    endTime: Date;
    /**
     * 
     * @type {number}
     * @memberof ShiftAreaSession
     */
    duration: number;
    /**
     * 
     * @type {ShiftArea}
     * @memberof ShiftAreaSession
     */
    shiftArea?: ShiftArea;
}

/**
 * Check if a given object implements the ShiftAreaSession interface.
 */
export function instanceOfShiftAreaSession(value: object): value is ShiftAreaSession {
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('shiftId' in value) || value['shiftId'] === undefined) return false;
    if (!('shiftAreaId' in value) || value['shiftAreaId'] === undefined) return false;
    if (!('startTime' in value) || value['startTime'] === undefined) return false;
    if (!('endTime' in value) || value['endTime'] === undefined) return false;
    if (!('duration' in value) || value['duration'] === undefined) return false;
    return true;
}

export function ShiftAreaSessionFromJSON(json: any): ShiftAreaSession {
    return ShiftAreaSessionFromJSONTyped(json, false);
}

export function ShiftAreaSessionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftAreaSession {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'companyId': json['companyId'],
        'shiftId': json['shiftId'],
        'shiftAreaId': json['shiftAreaId'],
        'startTime': (new Date(json['startTime'])),
        'endTime': (new Date(json['endTime'])),
        'duration': json['duration'],
        'shiftArea': json['shiftArea'] == null ? undefined : ShiftAreaFromJSON(json['shiftArea']),
    };
}

  export function ShiftAreaSessionToJSON(json: any): ShiftAreaSession {
      return ShiftAreaSessionToJSONTyped(json, false);
  }

  export function ShiftAreaSessionToJSONTyped(value?: ShiftAreaSession | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'shiftId': value['shiftId'],
        'shiftAreaId': value['shiftAreaId'],
        'startTime': ((value['startTime']).toISOString()),
        'endTime': ((value['endTime']).toISOString()),
        'duration': value['duration'],
        'shiftArea': ShiftAreaToJSON(value['shiftArea']),
    };
}

