/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkClockInData,
  BulkClockOutData,
  ClockInData,
  ClockInPinLoginRequest,
  ClockInPinLoginResponse,
  ClockOutData,
  RecordBreakData,
  RecordBreakResponse,
  RecordClockInResponse,
  RecordClockOutResponse,
} from '../models/index';
import {
    BulkClockInDataFromJSON,
    BulkClockInDataToJSON,
    BulkClockOutDataFromJSON,
    BulkClockOutDataToJSON,
    ClockInDataFromJSON,
    ClockInDataToJSON,
    ClockInPinLoginRequestFromJSON,
    ClockInPinLoginRequestToJSON,
    ClockInPinLoginResponseFromJSON,
    ClockInPinLoginResponseToJSON,
    ClockOutDataFromJSON,
    ClockOutDataToJSON,
    RecordBreakDataFromJSON,
    RecordBreakDataToJSON,
    RecordBreakResponseFromJSON,
    RecordBreakResponseToJSON,
    RecordClockInResponseFromJSON,
    RecordClockInResponseToJSON,
    RecordClockOutResponseFromJSON,
    RecordClockOutResponseToJSON,
} from '../models/index';

export interface BulkClockInRequest {
    bulkClockInData?: BulkClockInData;
}

export interface BulkClockOutRequest {
    bulkClockOutData?: BulkClockOutData;
}

export interface ClockInPinLoginOperationRequest {
    clockInPinLoginRequest?: ClockInPinLoginRequest;
}

export interface RecordBreakRequest {
    recordBreakData?: RecordBreakData;
}

export interface RecordClockInRequest {
    clockInData?: ClockInData;
}

export interface RecordClockOutRequest {
    clockOutData?: ClockOutData;
}

/**
 * 
 */
export class ClockingApi extends runtime.BaseAPI {

    /**
     * 
     * Bulk Clock In
     */
    async bulkClockInRaw(requestParameters: BulkClockInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.bulk-clockings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clockings/bulk/in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkClockInDataToJSON(requestParameters.bulkClockInData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 
     * Bulk Clock In
     */
    async bulkClockIn(requestParameters: BulkClockInRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkClockInRaw(requestParameters, initOverrides);
    }

    /**
     * Bulk Clock Out
     */
    async bulkClockOutRaw(requestParameters: BulkClockOutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.bulk-clockings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clockings/bulk/out`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BulkClockOutDataToJSON(requestParameters.bulkClockOutData),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk Clock Out
     */
    async bulkClockOut(requestParameters: BulkClockOutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkClockOutRaw(requestParameters, initOverrides);
    }

    /**
     * Supply an employee\'s clock in pin and receive a short-lived access token. The token will only be scoped to the employee\'s company and have a limited set of scopes.
     * Clock In Pin Login
     */
    async clockInPinLoginRaw(requestParameters: ClockInPinLoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClockInPinLoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clock-in-pin.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clockings/pin-login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClockInPinLoginRequestToJSON(requestParameters.clockInPinLoginRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClockInPinLoginResponseFromJSON(jsonValue));
    }

    /**
     * Supply an employee\'s clock in pin and receive a short-lived access token. The token will only be scoped to the employee\'s company and have a limited set of scopes.
     * Clock In Pin Login
     */
    async clockInPinLogin(requestParameters: ClockInPinLoginOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClockInPinLoginResponse> {
        const response = await this.clockInPinLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Record a break start / end event
     * Record Break Event
     */
    async recordBreakRaw(requestParameters: RecordBreakRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordBreakResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clockings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clockings/break`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RecordBreakDataToJSON(requestParameters.recordBreakData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordBreakResponseFromJSON(jsonValue));
    }

    /**
     * Record a break start / end event
     * Record Break Event
     */
    async recordBreak(requestParameters: RecordBreakRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordBreakResponse> {
        const response = await this.recordBreakRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Record a clock in event
     * Record Clock In Event
     */
    async recordClockInRaw(requestParameters: RecordClockInRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordClockInResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clockings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clockings/in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClockInDataToJSON(requestParameters.clockInData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordClockInResponseFromJSON(jsonValue));
    }

    /**
     * Record a clock in event
     * Record Clock In Event
     */
    async recordClockIn(requestParameters: RecordClockInRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordClockInResponse> {
        const response = await this.recordClockInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Record a clock out event
     * Record Clock Out Event
     */
    async recordClockOutRaw(requestParameters: RecordClockOutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RecordClockOutResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clockings.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clockings/out`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ClockOutDataToJSON(requestParameters.clockOutData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecordClockOutResponseFromJSON(jsonValue));
    }

    /**
     * Record a clock out event
     * Record Clock Out Event
     */
    async recordClockOut(requestParameters: RecordClockOutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RecordClockOutResponse> {
        const response = await this.recordClockOutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
