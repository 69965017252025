/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RedeemOfferCodeData
 */
export interface RedeemOfferCodeData {
    /**
     * 
     * @type {string}
     * @memberof RedeemOfferCodeData
     */
    offerCode: string;
}

/**
 * Check if a given object implements the RedeemOfferCodeData interface.
 */
export function instanceOfRedeemOfferCodeData(value: object): value is RedeemOfferCodeData {
    if (!('offerCode' in value) || value['offerCode'] === undefined) return false;
    return true;
}

export function RedeemOfferCodeDataFromJSON(json: any): RedeemOfferCodeData {
    return RedeemOfferCodeDataFromJSONTyped(json, false);
}

export function RedeemOfferCodeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedeemOfferCodeData {
    if (json == null) {
        return json;
    }
    return {
        
        'offerCode': json['offerCode'],
    };
}

  export function RedeemOfferCodeDataToJSON(json: any): RedeemOfferCodeData {
      return RedeemOfferCodeDataToJSONTyped(json, false);
  }

  export function RedeemOfferCodeDataToJSONTyped(value?: RedeemOfferCodeData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'offerCode': value['offerCode'],
    };
}

