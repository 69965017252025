/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { RedeemedOffer } from './RedeemedOffer';
import {
    RedeemedOfferFromJSON,
    RedeemedOfferFromJSONTyped,
    RedeemedOfferToJSON,
    RedeemedOfferToJSONTyped,
} from './RedeemedOffer';

/**
 * 
 * @export
 * @interface ListRedeemedOffersResponse
 */
export interface ListRedeemedOffersResponse {
    /**
     * 
     * @type {Array<RedeemedOffer>}
     * @memberof ListRedeemedOffersResponse
     */
    data: Array<RedeemedOffer>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListRedeemedOffersResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListRedeemedOffersResponse interface.
 */
export function instanceOfListRedeemedOffersResponse(value: object): value is ListRedeemedOffersResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListRedeemedOffersResponseFromJSON(json: any): ListRedeemedOffersResponse {
    return ListRedeemedOffersResponseFromJSONTyped(json, false);
}

export function ListRedeemedOffersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListRedeemedOffersResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(RedeemedOfferFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListRedeemedOffersResponseToJSON(json: any): ListRedeemedOffersResponse {
      return ListRedeemedOffersResponseToJSONTyped(json, false);
  }

  export function ListRedeemedOffersResponseToJSONTyped(value?: ListRedeemedOffersResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(RedeemedOfferToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

