/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PendingEmail
 */
export interface PendingEmail {
    /**
     * 
     * @type {string}
     * @memberof PendingEmail
     */
    newEmail: string;
    /**
     * 
     * @type {Date}
     * @memberof PendingEmail
     */
    expiresAt: Date;
}

/**
 * Check if a given object implements the PendingEmail interface.
 */
export function instanceOfPendingEmail(value: object): value is PendingEmail {
    if (!('newEmail' in value) || value['newEmail'] === undefined) return false;
    if (!('expiresAt' in value) || value['expiresAt'] === undefined) return false;
    return true;
}

export function PendingEmailFromJSON(json: any): PendingEmail {
    return PendingEmailFromJSONTyped(json, false);
}

export function PendingEmailFromJSONTyped(json: any, ignoreDiscriminator: boolean): PendingEmail {
    if (json == null) {
        return json;
    }
    return {
        
        'newEmail': json['newEmail'],
        'expiresAt': (new Date(json['expiresAt'])),
    };
}

  export function PendingEmailToJSON(json: any): PendingEmail {
      return PendingEmailToJSONTyped(json, false);
  }

  export function PendingEmailToJSONTyped(value?: PendingEmail | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'newEmail': value['newEmail'],
        'expiresAt': ((value['expiresAt']).toISOString()),
    };
}

