import spacetime from 'spacetime';

export const addDays = (
  date: Date,
  daysToAdd: number,
  timezone: string,
): Date => spacetime(date, timezone).add(daysToAdd, 'day').toNativeDate();

export const subtractDays = (
  date: Date,
  daysToSubtract: number,
  timezone: string,
): Date =>
  spacetime(date, timezone).subtract(daysToSubtract, 'day').toNativeDate();

export const addHours = (
  date: Date,
  hoursToAdd: number,
  timezone: string,
): Date => spacetime(date, timezone).add(hoursToAdd, 'hour').toNativeDate();

export const subtractMinutes = (
  date: Date,
  minutesToSubtract: number,
  timezone: string,
): Date =>
  spacetime(date, timezone)
    .subtract(minutesToSubtract, 'minute')
    .toNativeDate();
