export enum DateTimeFormat {
  day = 'day',
  dayMonth = 'dayMonth',
  dayMonthLong = 'dayMonthLong',
  dayMonthYear = 'dayMonthYear',
  dayMonthYearHourMinute = 'dayMonthYearHourMinute',
  dayMonthLongYear = 'dayMonthLongYear',
  dayMonthLongYearHourMinute = 'dayMonthLongYearHourMinute',
  weekday = 'weekday',
  weekdayShort = 'weekdayShort',
  weekdayDay = 'weekdayDay',
  weekdayLongDay = 'weekdayLongDay',
  weekdayDayMonth = 'weekdayDayMonth',
  weekdayLongDayMonthLong = 'weekdayLongDayMonthLong',
  weekdayLongDayMonthLongYear = 'weekdayLongDayMonthLongYear',
  weekdayDayYear = 'weekdayDayYear',
  weekdayDayMonthYear = 'weekdayDayMonthYear',
  weekdayDayMonthLongYear = 'weekdayDayMonthLongYear',
  weekdayShortMonthShortDayYear = 'weekdayShortMonthShortDayYear',
  monthDayYear = 'monthDayYear',
  monthLong = 'monthLong',
  monthShort = 'monthShort',
  monthYear = 'monthYear',
  monthShortYear = 'monthShortYear',
  hour = 'hour',
  hourMinute = 'hourMinute',
  hourMinuteSecond = 'hourMinuteSecond',
  year = 'year',
}

export const getI18nDateTimeFormats = (
  timeFormat: '12' | '24' = '24',
  timezone?: string,
): { [f: string]: Intl.DateTimeFormatOptions } => {
  const formats: { [f: string]: Intl.DateTimeFormatOptions } = {
    [DateTimeFormat.day]: {
      // '13
      day: 'numeric',
    },
    [DateTimeFormat.dayMonth]: {
      // Should be '13 Mar' but failing pipeline tests. Switched to 'Mar 13'
      day: 'numeric',
      month: 'short',
    },
    [DateTimeFormat.dayMonthLong]: {
      // '30 June'
      day: 'numeric',
      month: 'long',
    },
    [DateTimeFormat.dayMonthYear]: {
      // 'Should be '13 Mar 2020' but failing pipeline tests. Switched to 'Mar 13 2020'
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    [DateTimeFormat.dayMonthYearHourMinute]: {
      // 9 Mar 2028 @ 10:42
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    [DateTimeFormat.dayMonthLongYear]: {
      // '20 September 2020'
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    [DateTimeFormat.dayMonthLongYearHourMinute]: {
      // 9 March 2028 @ 10:42
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    [DateTimeFormat.weekday]: {
      // 'Tuesday'
      weekday: 'long',
    },
    [DateTimeFormat.weekdayShort]: {
      // 'Tue'
      weekday: 'short',
    },
    [DateTimeFormat.weekdayDay]: {
      // 'Fri 13'
      weekday: 'short',
      day: 'numeric',
    },
    [DateTimeFormat.weekdayLongDay]: {
      // 'Friday 13'
      weekday: 'long',
      day: 'numeric',
    },
    [DateTimeFormat.weekdayDayMonth]: {
      // 'Fri, 13 Mar'
      weekday: 'short',
      day: 'numeric',
      month: 'short',
    },
    [DateTimeFormat.weekdayLongDayMonthLong]: {
      // 'Friday, 13 March'
      weekday: 'long',
      day: 'numeric',
      month: 'long',
    },
    [DateTimeFormat.weekdayLongDayMonthLongYear]: {
      // 'Friday, 13 March 2023'
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    [DateTimeFormat.weekdayDayYear]: {
      weekday: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    [DateTimeFormat.weekdayDayMonthYear]: {
      // 'Fri, 13 Mar 2020'
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    [DateTimeFormat.weekdayDayMonthLongYear]: {
      weekday: 'short',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    [DateTimeFormat.weekdayShortMonthShortDayYear]: {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    },
    [DateTimeFormat.monthDayYear]: {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    },
    [DateTimeFormat.monthLong]: {
      // 'September'
      month: 'long',
    },
    [DateTimeFormat.monthShort]: {
      // 'Sep'
      month: 'short',
    },
    [DateTimeFormat.monthShortYear]: {
      // 'Mar 2020'
      month: 'short',
      year: 'numeric',
    },
    [DateTimeFormat.monthYear]: {
      // 'March 2020'
      month: 'long',
      year: 'numeric',
    },
    [DateTimeFormat.hour]: {
      // 5 pm (when 12 hour locale); 17 (when 24 hour locale)
      // 12 hour set as false for default
      hour: 'numeric',
    },
    [DateTimeFormat.hourMinute]: {
      // 5:39 pm (when 12 hour locale); 17:39 (when 24 hour locale)
      // 12 hour set as false for default
      hour: 'numeric',
      minute: 'numeric',
    },
    [DateTimeFormat.hourMinuteSecond]: {
      // 5:39:23 pm (when 12 hour locale); 17:39:23 (when 24 hour locale)
      // 12 hour set as false for default
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
    [DateTimeFormat.year]: {
      // single output of long numeric year: '2022'
      year: 'numeric',
    },
  };

  /**
   * Depending on whether the company wants to use a 12-hour clock, or a 24-hour one, we need to modify the default
   * format of times. *Don't* use `hour12` as this doesn't work consistently across Chrome and Firefox. Instead, use
   * `hourCycle`, which seems to have more standardised behaviour.
   */
  Object.values(formats).forEach((f: Intl.DateTimeFormatOptions) => {
    /**
     * We use h23 in order to show midnight as 00:00 rather than 24:00. It's possible that the latter is used in
     * some locales, but as we're currently focussed on UK and US, we won't worry about this for now. If 24:00 is
     * needed in the future, we'll have to apply these conditionally upon the locale.
     */
    f.hourCycle = timeFormat === '12' ? 'h12' : 'h23';
    f.timeZone = timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone;
  });

  return formats;
};
