/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShiftConflict
 */
export interface ShiftConflict {
    /**
     * 
     * @type {number}
     * @memberof ShiftConflict
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftConflict
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftConflict
     */
    shiftId: number;
    /**
     * 
     * @type {string}
     * @memberof ShiftConflict
     */
    type: ShiftConflictTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof ShiftConflict
     */
    foreignId: number;
}

/**
* @export
* @enum {string}
*/
export enum ShiftConflictTypeEnum {
    Absence = 'Absence',
    Leave = 'Leave',
    Shift = 'Shift',
    Unavailability = 'Unavailability'
}


/**
 * Check if a given object implements the ShiftConflict interface.
 */
export function instanceOfShiftConflict(value: object): value is ShiftConflict {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('shiftId' in value) || value['shiftId'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('foreignId' in value) || value['foreignId'] === undefined) return false;
    return true;
}

export function ShiftConflictFromJSON(json: any): ShiftConflict {
    return ShiftConflictFromJSONTyped(json, false);
}

export function ShiftConflictFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftConflict {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'shiftId': json['shiftId'],
        'type': json['type'],
        'foreignId': json['foreignId'],
    };
}

  export function ShiftConflictToJSON(json: any): ShiftConflict {
      return ShiftConflictToJSONTyped(json, false);
  }

  export function ShiftConflictToJSONTyped(value?: ShiftConflict | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'shiftId': value['shiftId'],
        'type': value['type'],
        'foreignId': value['foreignId'],
    };
}

