import { defineStore } from 'pinia';
import { authApi, usersApi } from '../api';
import { AuthenticationData, User } from '../sdk/auth';
import { decodeJwtToken } from '../lib/functions/authFunctions';

interface AuthState {
  user: User | null;
  token: string | null;
  tokenExpiry: number;
  scopes: string[];
}

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({
    user: null,
    token: null,
    tokenExpiry: 0,
    scopes: [],
  }),

  actions: {
    async login(credentials: {
      email: string;
      password: string;
    }): Promise<AuthenticationData> {
      return authApi()
        .login({
          loginData: {
            ...credentials,
            scopes: ['api.auth', 'api.v1'],
          },
        })
        .then(({ data: authData }) => {
          this.setAuthData(authData);
          return authData;
        });
    },

    async refresh(): Promise<AuthenticationData> {
      const { data: authData } = await authApi().refreshAccessToken({
        refreshData: {},
      });
      this.setAuthData(authData);
      return authData;
    },

    async fetchAuthenticatedUser() {
      const { data: user } = await usersApi().showAuthenticatedUser({});
      this.user = user;
    },

    setAuthData(data: AuthenticationData): void {
      const { token } = data;
      const tokenData = decodeJwtToken(token);

      this.token = token;
      this.tokenExpiry = tokenData.exp;
      this.scopes = tokenData.scopes;
    },

    clearAuthData(): void {
      this.token = null;
      this.tokenExpiry = 0;
      this.scopes = [];
    },

    async logout() {
      await authApi().logout();
      this.clearAuthData();
    },
  },
});
