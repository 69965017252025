import { keyOfFirstTruthyValue } from './objectFunctions.ts';
import { addDays, subtractDays } from './dateArithmetic.ts';

export const isValidIsoDateString = (input: any) =>
  typeof input === 'string' &&
  !!input.match(/\d{4}-\d{2}-\d{2}/) &&
  // Timezone note: This use of Date.getTime() is deliberate, and doesn't need to be timezone-aware.
  // It's just to check that the input can be constructed as a valid date.
  !Number.isNaN(new Date(input).getTime()) &&
  // Check if the current environment's implementation of Date has converted an invalid date like 30th Feb
  // For instance, NodeJS will convert 30th Feb to 1st March.
  // Timezone note: This use of the date constructor is fine, as date-only strings are treated as UTC, and we're
  // comparing the constructed date object with its UTC string.
  new Date(input).toISOString().substring(0, 10) === input;

export const isInPeriod = (start: Date, end: Date, targetDate: Date): boolean =>
  targetDate < end && targetDate > start;

export enum DayTimePeriod {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
  Night = 'night',
}
// https://english.stackexchange.com/questions/28498/precise-names-for-parts-of-a-day
export const getDayTimePeriod = (
  time: Date,
  timezone: string,
): DayTimePeriod | undefined => {
  const date = new Date(time);
  return keyOfFirstTruthyValue({
    // 5am to 12pm = Morning
    [DayTimePeriod.Morning]: isInPeriod(
      new Date(date.setHours(5, 0)),
      new Date(date.setHours(12, 0)),
      time,
    ),
    // 12pm to 5pm = Afternoon
    [DayTimePeriod.Afternoon]: isInPeriod(
      new Date(date.setHours(12, 0)),
      new Date(date.setHours(17, 0)),
      time,
    ),
    // 5pm to 9pm = Evening
    [DayTimePeriod.Evening]: isInPeriod(
      new Date(date.setHours(17, 0)),
      new Date(date.setHours(21, 0)),
      time,
    ),
    // 9pm to 5am = Night
    [DayTimePeriod.Night]:
      isInPeriod(
        subtractDays(new Date(date.setHours(21, 0)), 1, timezone),
        new Date(date.setHours(5, 0)),
        time,
      ) ||
      isInPeriod(
        new Date(date.setHours(21, 0)),
        addDays(new Date(date.setHours(5, 0)), 1, timezone),
        time,
      ),
  });
};
