/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticationDataMetaMfa
 */
export interface AuthenticationDataMetaMfa {
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationDataMetaMfa
     */
    required: boolean;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationDataMetaMfa
     */
    status: AuthenticationDataMetaMfaStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationDataMetaMfa
     */
    challenge: boolean;
}

/**
* @export
* @enum {string}
*/
export enum AuthenticationDataMetaMfaStatusEnum {
    Present = 'present',
    Absent = 'absent'
}


/**
 * Check if a given object implements the AuthenticationDataMetaMfa interface.
 */
export function instanceOfAuthenticationDataMetaMfa(value: object): value is AuthenticationDataMetaMfa {
    if (!('required' in value) || value['required'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('challenge' in value) || value['challenge'] === undefined) return false;
    return true;
}

export function AuthenticationDataMetaMfaFromJSON(json: any): AuthenticationDataMetaMfa {
    return AuthenticationDataMetaMfaFromJSONTyped(json, false);
}

export function AuthenticationDataMetaMfaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationDataMetaMfa {
    if (json == null) {
        return json;
    }
    return {
        
        'required': json['required'],
        'status': json['status'],
        'challenge': json['challenge'],
    };
}

  export function AuthenticationDataMetaMfaToJSON(json: any): AuthenticationDataMetaMfa {
      return AuthenticationDataMetaMfaToJSONTyped(json, false);
  }

  export function AuthenticationDataMetaMfaToJSONTyped(value?: AuthenticationDataMetaMfa | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'required': value['required'],
        'status': value['status'],
        'challenge': value['challenge'],
    };
}

