/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingEventLine
 */
export interface BillingEventLine {
    /**
     * 
     * @type {string}
     * @memberof BillingEventLine
     */
    name: BillingEventLineNameEnum;
    /**
     * 
     * @type {number}
     * @memberof BillingEventLine
     */
    estimatedCost: number;
}

/**
* @export
* @enum {string}
*/
export enum BillingEventLineNameEnum {
    AdditionalEmployees = 'Additional Employees',
    Subscription = 'Subscription'
}


/**
 * Check if a given object implements the BillingEventLine interface.
 */
export function instanceOfBillingEventLine(value: object): value is BillingEventLine {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('estimatedCost' in value) || value['estimatedCost'] === undefined) return false;
    return true;
}

export function BillingEventLineFromJSON(json: any): BillingEventLine {
    return BillingEventLineFromJSONTyped(json, false);
}

export function BillingEventLineFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingEventLine {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'estimatedCost': json['estimatedCost'],
    };
}

  export function BillingEventLineToJSON(json: any): BillingEventLine {
      return BillingEventLineToJSONTyped(json, false);
  }

  export function BillingEventLineToJSONTyped(value?: BillingEventLine | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'estimatedCost': value['estimatedCost'],
    };
}

