/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingSubscription } from './BillingSubscription';
import {
    BillingSubscriptionFromJSON,
    BillingSubscriptionFromJSONTyped,
    BillingSubscriptionToJSON,
    BillingSubscriptionToJSONTyped,
} from './BillingSubscription';

/**
 * 
 * @export
 * @interface ShowLatestSubscriptionResponse
 */
export interface ShowLatestSubscriptionResponse {
    /**
     * 
     * @type {BillingSubscription}
     * @memberof ShowLatestSubscriptionResponse
     */
    data: BillingSubscription;
}

/**
 * Check if a given object implements the ShowLatestSubscriptionResponse interface.
 */
export function instanceOfShowLatestSubscriptionResponse(value: object): value is ShowLatestSubscriptionResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowLatestSubscriptionResponseFromJSON(json: any): ShowLatestSubscriptionResponse {
    return ShowLatestSubscriptionResponseFromJSONTyped(json, false);
}

export function ShowLatestSubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowLatestSubscriptionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingSubscriptionFromJSON(json['data']),
    };
}

  export function ShowLatestSubscriptionResponseToJSON(json: any): ShowLatestSubscriptionResponse {
      return ShowLatestSubscriptionResponseToJSONTyped(json, false);
  }

  export function ShowLatestSubscriptionResponseToJSONTyped(value?: ShowLatestSubscriptionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': BillingSubscriptionToJSON(value['data']),
    };
}

