import { RouteLocationNamedRaw, RouteRecordRaw } from 'vue-router';
import { LogoutReason } from '../lib/auth/logoutReason.ts';

/** Our routes have optional properties in addition to those expected by vue-router */
export type OurRouteRecord = RouteRecordRaw & {
  childOf?: () => string;
  route?(...args: any[]): RouteLocationNamedRaw;
};

export type OurRouteConfig = { [r: string]: OurRouteRecord };

/**
 * We define our routes in an almost identical way to how you normally would for vue-router, but with the exception
 * that we don't nest them - it's essentially a flat structure. This allows us to reference routes by name directly
 * without needing to know whether a route is nested (from a component point of view) inside another.
 *
 * We represent a parent-child relationship by adding a 'childOf' function to any route that should be nested inside
 * another. Before passing the routes to vue-router, we take a copy of the routes and transform it into the nested
 * structure that it accepts.
 */
export const routes = {
  default: {
    name: 'default',
    path: '/',
    redirect: '/pin-entry',
  },
  login: {
    name: 'login',
    path: '/login',
    component: () => import('../views/auth/Login.vue'),
    route: (): RouteLocationNamedRaw => ({ name: routes.login.name }),
    meta: {
      allowsGuest: true,
      allowsBootstrap: true,
    },
  },
  logout: {
    name: 'logout',
    path: '/logout',
    component: () => import('../views/auth/Logout.vue'),
    route: ({
      reason,
    }: {
      reason?: LogoutReason;
    } = {}): RouteLocationNamedRaw => ({
      name: routes.logout.name,
      query: {
        ...(reason && { r: reason.toString() }),
      },
    }),
    meta: {
      allowsGuest: true,
    },
  },
  selectCompany: {
    name: 'selectCompany',
    path: '/select-company',
    component: () => import('../views/SelectCompany.vue'),
    route: (): RouteLocationNamedRaw => ({ name: routes.selectCompany.name }),
    meta: {
      allowsBootstrap: true,
    },
  },
  selectPortal: {
    name: 'selectPortal',
    path: '/select-portal',
    component: () => import('../views/SelectPortal.vue'),
    route: (): RouteLocationNamedRaw => ({ name: routes.selectPortal.name }),
    meta: {
      allowsBootstrap: true,
    },
  },
  pinEntry: {
    name: 'pinEntry',
    path: '/pin-entry',
    component: () => import('../views/PinEntry.vue'),
    route: (): RouteLocationNamedRaw => ({ name: routes.pinEntry.name }),
    meta: {
      bootstrappedLandingPage: true,
    },
  },
  handleClocking: {
    name: 'handleClocking',
    path: '/handle-clocking',
    component: () => import('../views/HandleClocking.vue'),
    route: (): RouteLocationNamedRaw => ({ name: routes.handleClocking.name }),
  },
} satisfies OurRouteConfig;
