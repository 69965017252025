/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BillingOverview
 */
export interface BillingOverview {
    /**
     * Whether the company has added billing details.
     * @type {boolean}
     * @memberof BillingOverview
     */
    hasDetails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof BillingOverview
     */
    hasPaymentMethod: boolean;
}

/**
 * Check if a given object implements the BillingOverview interface.
 */
export function instanceOfBillingOverview(value: object): value is BillingOverview {
    if (!('hasDetails' in value) || value['hasDetails'] === undefined) return false;
    if (!('hasPaymentMethod' in value) || value['hasPaymentMethod'] === undefined) return false;
    return true;
}

export function BillingOverviewFromJSON(json: any): BillingOverview {
    return BillingOverviewFromJSONTyped(json, false);
}

export function BillingOverviewFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingOverview {
    if (json == null) {
        return json;
    }
    return {
        
        'hasDetails': json['hasDetails'],
        'hasPaymentMethod': json['hasPaymentMethod'],
    };
}

  export function BillingOverviewToJSON(json: any): BillingOverview {
      return BillingOverviewToJSONTyped(json, false);
  }

  export function BillingOverviewToJSONTyped(value?: BillingOverview | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'hasDetails': value['hasDetails'],
        'hasPaymentMethod': value['hasPaymentMethod'],
    };
}

