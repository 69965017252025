/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Credit } from './Credit';
import {
    CreditFromJSON,
    CreditFromJSONTyped,
    CreditToJSON,
    CreditToJSONTyped,
} from './Credit';

/**
 * 
 * @export
 * @interface UpdateCreditRenewalSettingsResponse
 */
export interface UpdateCreditRenewalSettingsResponse {
    /**
     * 
     * @type {Credit}
     * @memberof UpdateCreditRenewalSettingsResponse
     */
    data: Credit;
}

/**
 * Check if a given object implements the UpdateCreditRenewalSettingsResponse interface.
 */
export function instanceOfUpdateCreditRenewalSettingsResponse(value: object): value is UpdateCreditRenewalSettingsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateCreditRenewalSettingsResponseFromJSON(json: any): UpdateCreditRenewalSettingsResponse {
    return UpdateCreditRenewalSettingsResponseFromJSONTyped(json, false);
}

export function UpdateCreditRenewalSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCreditRenewalSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CreditFromJSON(json['data']),
    };
}

  export function UpdateCreditRenewalSettingsResponseToJSON(json: any): UpdateCreditRenewalSettingsResponse {
      return UpdateCreditRenewalSettingsResponseToJSONTyped(json, false);
  }

  export function UpdateCreditRenewalSettingsResponseToJSONTyped(value?: UpdateCreditRenewalSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CreditToJSON(value['data']),
    };
}

