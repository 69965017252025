/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClockInPortal } from './ClockInPortal';
import {
    ClockInPortalFromJSON,
    ClockInPortalFromJSONTyped,
    ClockInPortalToJSON,
    ClockInPortalToJSONTyped,
} from './ClockInPortal';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListClockInPortalsResponse
 */
export interface ListClockInPortalsResponse {
    /**
     * 
     * @type {Array<ClockInPortal>}
     * @memberof ListClockInPortalsResponse
     */
    data: Array<ClockInPortal>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListClockInPortalsResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListClockInPortalsResponse interface.
 */
export function instanceOfListClockInPortalsResponse(value: object): value is ListClockInPortalsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListClockInPortalsResponseFromJSON(json: any): ListClockInPortalsResponse {
    return ListClockInPortalsResponseFromJSONTyped(json, false);
}

export function ListClockInPortalsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListClockInPortalsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ClockInPortalFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListClockInPortalsResponseToJSON(json: any): ListClockInPortalsResponse {
      return ListClockInPortalsResponseToJSONTyped(json, false);
  }

  export function ListClockInPortalsResponseToJSONTyped(value?: ListClockInPortalsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ClockInPortalToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

