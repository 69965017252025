/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    LocationToJSONTyped,
} from './Location';

/**
 * 
 * @export
 * @interface ShowLocationResponse
 */
export interface ShowLocationResponse {
    /**
     * 
     * @type {Location}
     * @memberof ShowLocationResponse
     */
    data: Location;
}

/**
 * Check if a given object implements the ShowLocationResponse interface.
 */
export function instanceOfShowLocationResponse(value: object): value is ShowLocationResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowLocationResponseFromJSON(json: any): ShowLocationResponse {
    return ShowLocationResponseFromJSONTyped(json, false);
}

export function ShowLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowLocationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LocationFromJSON(json['data']),
    };
}

  export function ShowLocationResponseToJSON(json: any): ShowLocationResponse {
      return ShowLocationResponseToJSONTyped(json, false);
  }

  export function ShowLocationResponseToJSONTyped(value?: ShowLocationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LocationToJSON(value['data']),
    };
}

