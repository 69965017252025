/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingSubscriptionCancellation } from './BillingSubscriptionCancellation';
import {
    BillingSubscriptionCancellationFromJSON,
    BillingSubscriptionCancellationFromJSONTyped,
    BillingSubscriptionCancellationToJSON,
    BillingSubscriptionCancellationToJSONTyped,
} from './BillingSubscriptionCancellation';

/**
 * 
 * @export
 * @interface ShowSubscriptionCancellationResponse
 */
export interface ShowSubscriptionCancellationResponse {
    /**
     * 
     * @type {BillingSubscriptionCancellation}
     * @memberof ShowSubscriptionCancellationResponse
     */
    data: BillingSubscriptionCancellation;
}

/**
 * Check if a given object implements the ShowSubscriptionCancellationResponse interface.
 */
export function instanceOfShowSubscriptionCancellationResponse(value: object): value is ShowSubscriptionCancellationResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowSubscriptionCancellationResponseFromJSON(json: any): ShowSubscriptionCancellationResponse {
    return ShowSubscriptionCancellationResponseFromJSONTyped(json, false);
}

export function ShowSubscriptionCancellationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowSubscriptionCancellationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': BillingSubscriptionCancellationFromJSON(json['data']),
    };
}

  export function ShowSubscriptionCancellationResponseToJSON(json: any): ShowSubscriptionCancellationResponse {
      return ShowSubscriptionCancellationResponseToJSONTyped(json, false);
  }

  export function ShowSubscriptionCancellationResponseToJSONTyped(value?: ShowSubscriptionCancellationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': BillingSubscriptionCancellationToJSON(value['data']),
    };
}

