/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClockingSettings } from './ClockingSettings';
import {
    ClockingSettingsFromJSON,
    ClockingSettingsFromJSONTyped,
    ClockingSettingsToJSON,
    ClockingSettingsToJSONTyped,
} from './ClockingSettings';

/**
 * 
 * @export
 * @interface ShowClockingSettingsResponse
 */
export interface ShowClockingSettingsResponse {
    /**
     * 
     * @type {ClockingSettings}
     * @memberof ShowClockingSettingsResponse
     */
    data: ClockingSettings;
}

/**
 * Check if a given object implements the ShowClockingSettingsResponse interface.
 */
export function instanceOfShowClockingSettingsResponse(value: object): value is ShowClockingSettingsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowClockingSettingsResponseFromJSON(json: any): ShowClockingSettingsResponse {
    return ShowClockingSettingsResponseFromJSONTyped(json, false);
}

export function ShowClockingSettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowClockingSettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ClockingSettingsFromJSON(json['data']),
    };
}

  export function ShowClockingSettingsResponseToJSON(json: any): ShowClockingSettingsResponse {
      return ShowClockingSettingsResponseToJSONTyped(json, false);
  }

  export function ShowClockingSettingsResponseToJSONTyped(value?: ShowClockingSettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ClockingSettingsToJSON(value['data']),
    };
}

