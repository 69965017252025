import { fetchAbort } from './fetchAbort';
import {
  addUriHeader,
  authenticate,
  displayErrorsAsToasts,
  includeCredentials,
  redirectIfUnauthenticated,
} from './middleware';
import Qs from 'qs';
import {
  ApiVersion,
  ApiVersionClassMap,
  ApiVersionTypeMap,
  middlewareCreator,
} from './types';

/**
 * Types specific for the factory classes, not shared in types.ts.
 */
type ApiCreator<
  A extends ApiVersion,
  T extends ApiVersionTypeMap[A]['BaseApi'],
> = new (c: ApiVersionTypeMap[A]['Configuration']) => T;

type ConfigurationCreator<A extends ApiVersion> = new (
  c: ApiVersionTypeMap[A]['ConfigurationParameters'],
) => ApiVersionTypeMap[A]['Configuration'];

type ApiFactoryConfig<A extends ApiVersion> = {
  displayErrorsAsToasts: boolean;
  middleware: ApiVersionTypeMap[A]['Middleware'][];
};
type ApiFactoryConfigPartial<A extends ApiVersion> = Partial<
  ApiFactoryConfig<A>
>;

/**
 * Generic functions used for creating configurations
 */
const apiUrl = (v: string): string =>
  `https://${import.meta.env.VITE_API_BASE_URL}/${v}`;
const queryString =
  <A extends ApiVersion>() =>
  (params: ApiVersionTypeMap[A]['HttpQuery']): string => {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      encodeValuesOnly: true,
    });
  };

const getDefaultApiFactoryConfig = <
  A extends ApiVersion,
>(): ApiFactoryConfig<A> => {
  return {
    displayErrorsAsToasts: true,
    middleware: [
      authenticate<A>(),
      includeCredentials<A>(),
      redirectIfUnauthenticated<A>(),
      addUriHeader<A>(),
    ],
  };
};

class ApiEntityCache<A extends ApiVersion> {
  private store: Record<string, ApiVersionTypeMap[A]['BaseApi']> = {};

  private key<T extends ApiVersionTypeMap[A]['BaseApi']>(
    creator: ApiCreator<A, T>,
    c: ApiFactoryConfig<A>,
  ): string {
    return `${creator.name}_${btoa(JSON.stringify(c))}`;
  }

  public get<T extends ApiVersionTypeMap[A]['BaseApi']>(
    creator: ApiCreator<A, T>,
    c: ApiFactoryConfig<A>,
  ): T | null {
    return (this.store[this.key(creator, c)] ?? null) as T;
  }

  public put<T extends ApiVersionTypeMap[A]['BaseApi']>(
    creator: ApiCreator<A, T>,
    c: ApiFactoryConfig<A>,
    i: T,
  ): T {
    this.store[this.key(creator, c)] = i;

    return i;
  }
}

class ApiConfigurationFactory<A extends ApiVersion> {
  private customConfigurations: Record<
    string,
    ApiVersionTypeMap[A]['Configuration']
  > = {};

  public constructor(private type: A) {}

  public create<T extends ApiVersionTypeMap[A]['BaseApi']>(
    api: ApiCreator<A, T>,
    cp: ApiFactoryConfigPartial<A>,
  ): ApiVersionTypeMap[A]['Configuration'] {
    return this.createRaw<T>(
      api,
      ApiVersionClassMap[this.type].Configuration,
      cp,
    );
  }

  public registerCustomDefaultConfiguration<
    T extends ApiVersionTypeMap[A]['BaseApi'],
  >(
    api: ApiCreator<A, T>,
    c: ApiVersionTypeMap[A]['ConfigurationParameters'],
  ): void {
    this.registerCustomDefaultConfigurationRaw(
      api,
      c,
      ApiVersionClassMap[this.type]['Configuration'],
    );
  }

  public registerCustomDefaultConfigurationRaw<
    T extends ApiVersionTypeMap[A]['BaseApi'],
  >(
    api: ApiCreator<A, T>,
    c: ApiVersionTypeMap[A]['ConfigurationParameters'],
    creator: ConfigurationCreator<A>,
  ): void {
    this.customConfigurations[api.name] = new creator(c);
  }

  public makeConfigurationParameters(
    c: Partial<ApiVersionTypeMap[A]['ConfigurationParameters']>,
  ): ApiVersionTypeMap[A]['ConfigurationParameters'] {
    return {
      basePath: apiUrl(this.type),
      queryParamsStringify: queryString<A>(),
      fetchApi: fetchAbort,
      middleware: [],
      ...c,
    };
  }

  private createRaw<T extends ApiVersionTypeMap[A]['BaseApi']>(
    api: ApiCreator<A, T>,
    creator: ConfigurationCreator<A>,
    cp: ApiFactoryConfigPartial<A>,
  ): ApiVersionTypeMap[A]['Configuration'] {
    const customConfig = this.customConfigurations[api.name] ?? null;

    if (customConfig !== null && Object.values(cp).length === 0) {
      return customConfig;
    }

    const defaultConfig = new creator(this.makeConfigurationParameters({}));
    const factoryConfig = {
      ...getDefaultApiFactoryConfig(),
      ...cp,
    };

    if (factoryConfig.displayErrorsAsToasts) {
      defaultConfig.middleware.push(displayErrorsAsToasts<A>());
    }

    if (factoryConfig.middleware.length) {
      defaultConfig.middleware.push(...factoryConfig.middleware);
    }

    return defaultConfig;
  }
}

export class ApiFactory<A extends ApiVersion> {
  private store = new ApiEntityCache<A>();
  private configurationFactory: ApiConfigurationFactory<A>;

  public constructor(private type: A) {
    this.configurationFactory = new ApiConfigurationFactory(this.type);
  }

  public prepare<T extends ApiVersionTypeMap[A]['BaseApi']>(
    Creator: ApiCreator<A, T>,
  ): (cp?: ApiFactoryConfigPartial<A>) => T {
    return (cp: ApiFactoryConfigPartial<A> = {}): T => this.create(Creator, cp);
  }

  public create<T extends ApiVersionTypeMap[A]['BaseApi']>(
    creator: ApiCreator<A, T>,
    cp: ApiFactoryConfigPartial<A> = {},
  ): T {
    const config = { ...getDefaultApiFactoryConfig<A>(), ...cp };
    const existing = this.store.get(creator, config);

    if (existing !== null) {
      return existing;
    }

    const instance = new creator(this.configurationFactory.create(creator, cp));

    return this.store.put(creator, config, instance);
  }

  public createMiddleware(
    m: middlewareCreator,
  ): ApiVersionTypeMap[A]['Middleware'] {
    return m<A>();
  }

  public registerCustomDefaultConfiguration<
    T extends ApiVersionTypeMap[A]['BaseApi'],
  >(
    api: ApiCreator<A, T>,
    c: ApiVersionTypeMap[A]['ConfigurationParameters'],
  ): void {
    this.configurationFactory.registerCustomDefaultConfiguration(api, c);
  }

  public makeConfigurationParameters(
    c: Partial<ApiVersionTypeMap[A]['ConfigurationParameters']>,
  ): ApiVersionTypeMap[A]['ConfigurationParameters'] {
    return this.configurationFactory.makeConfigurationParameters(c);
  }
}

export const authApiFactory = new ApiFactory(ApiVersion.Auth);
export const v1ApiFactory = new ApiFactory(ApiVersion.V1);
