/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Offer } from './Offer';
import {
    OfferFromJSON,
    OfferFromJSONTyped,
    OfferToJSON,
    OfferToJSONTyped,
} from './Offer';

/**
 * 
 * @export
 * @interface ShowOfferResponse
 */
export interface ShowOfferResponse {
    /**
     * 
     * @type {Offer}
     * @memberof ShowOfferResponse
     */
    data: Offer;
}

/**
 * Check if a given object implements the ShowOfferResponse interface.
 */
export function instanceOfShowOfferResponse(value: object): value is ShowOfferResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowOfferResponseFromJSON(json: any): ShowOfferResponse {
    return ShowOfferResponseFromJSONTyped(json, false);
}

export function ShowOfferResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowOfferResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': OfferFromJSON(json['data']),
    };
}

  export function ShowOfferResponseToJSON(json: any): ShowOfferResponse {
      return ShowOfferResponseToJSONTyped(json, false);
  }

  export function ShowOfferResponseToJSONTyped(value?: ShowOfferResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': OfferToJSON(value['data']),
    };
}

