import spacetime from 'spacetime';
import { abortAndLogout } from './fetchAbort';
import { ApiVersion, ApiVersionTypeMap, middlewareCreator } from './types';
import { showErrorToast, showWarningToast } from '../functions/toastFunctions';
import { useAuthStore } from '../../stores/auth';
import { useBootstrapStore } from '../../stores/bootstrap.ts';
import { usePinAuthStore } from '../../stores/pinAuth.ts';

// Pre request middleware
export const includeCredentials: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    pre(
      context: ApiVersionTypeMap[A]['RequestContext'],
    ): Promise<ApiVersionTypeMap[A]['FetchParams']> {
      context.init.credentials = 'include';

      return Promise.resolve(context);
    },
  };
};

export const authenticate: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async pre(
      context: ApiVersionTypeMap[A]['RequestContext'],
    ): Promise<ApiVersionTypeMap[A]['FetchParams']> {
      const authStore = useAuthStore();
      const pinAuthStore = usePinAuthStore();
      const bootstrapStore = useBootstrapStore();

      if (authStore.token !== null) {
        if (
          spacetime
            .now()
            .add(2, 'minute')
            .isAfter(spacetime(authStore.tokenExpiry * 1000))
        ) {
          await authStore.refresh();
        }

        // Once we've got a temporary employee token, always use it for api requests
        // Otherwise fallback to the authenticated user
        // @ts-ignore
        context.init.headers['Authorization'] = `Bearer ${
          pinAuthStore.temporaryEmployeeToken ?? authStore.token
        }`;

        // @ts-ignore
        context.init.headers['x-company-id'] = bootstrapStore.company?.id;
      }

      return Promise.resolve(context);
    },
  };
};

export const addUriHeader = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async pre(
      context: ApiVersionTypeMap[A]['RequestContext'],
    ): Promise<ApiVersionTypeMap[A]['FetchParams']> {
      // @ts-ignore
      context.init.headers['x-spa-uri'] = window.location.toString();

      return Promise.resolve(context);
    },
  };
};

// Post request middleware
export const redirectIfUnauthenticated: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async post(
      context: ApiVersionTypeMap[A]['ResponseContext'],
    ): Promise<Response | void> {
      const { response } = context;
      // When we encounter a 401 response, don't show any error message and instead
      // redirect to the logout screen with a relevant message.
      if (response.status === 401) {
        abortAndLogout();

        return;
      }

      return response;
    },
  };
};

export const displayErrorsAsToasts: middlewareCreator = <
  A extends ApiVersion,
>(): ApiVersionTypeMap[A]['Middleware'] => {
  return {
    async post(
      context: ApiVersionTypeMap[A]['ResponseContext'],
    ): Promise<Response> {
      const { response } = context;

      if (response.status >= 400) {
        const data = await response.clone().json();

        if (data.errors && Object.keys(data.errors).length) {
          /** @todo Get a better way of showing validation errors. For the time being, we'll just show the first one. */
          // @ts-ignore
          showWarningToast(Object.values(data.errors)[0]);
        } else if (data.message) {
          showErrorToast(data.message);
        }
      }

      return response;
    },
  };
};
