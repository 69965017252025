/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateClockInPortalData,
  CreateClockInPortalResponse,
  ListClockInPortalsResponse,
  ShowClockInPortalResponse,
  UpdateClockInPortalData,
  UpdateClockInPortalResponse,
} from '../models/index';
import {
    CreateClockInPortalDataFromJSON,
    CreateClockInPortalDataToJSON,
    CreateClockInPortalResponseFromJSON,
    CreateClockInPortalResponseToJSON,
    ListClockInPortalsResponseFromJSON,
    ListClockInPortalsResponseToJSON,
    ShowClockInPortalResponseFromJSON,
    ShowClockInPortalResponseToJSON,
    UpdateClockInPortalDataFromJSON,
    UpdateClockInPortalDataToJSON,
    UpdateClockInPortalResponseFromJSON,
    UpdateClockInPortalResponseToJSON,
} from '../models/index';

export interface CreateClockInPortalRequest {
    createClockInPortalData?: CreateClockInPortalData;
}

export interface DeleteClockInPortalRequest {
    id: number;
}

export interface ShowClockInPortalRequest {
    id: number;
}

export interface UpdateClockInPortalRequest {
    id: number;
    updateClockInPortalData?: UpdateClockInPortalData;
}

/**
 * 
 */
export class ClockInPortalApi extends runtime.BaseAPI {

    /**
     * Create a new clock in portal
     * Create Clock In Portal
     */
    async createClockInPortalRaw(requestParameters: CreateClockInPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateClockInPortalResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clock-in-portal.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clock-in-portals`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateClockInPortalDataToJSON(requestParameters.createClockInPortalData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateClockInPortalResponseFromJSON(jsonValue));
    }

    /**
     * Create a new clock in portal
     * Create Clock In Portal
     */
    async createClockInPortal(requestParameters: CreateClockInPortalRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateClockInPortalResponse> {
        const response = await this.createClockInPortalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a single clock in portal
     * Delete Clock In Portal
     */
    async deleteClockInPortalRaw(requestParameters: DeleteClockInPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteClockInPortal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clock-in-portal.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clock-in-portals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a single clock in portal
     * Delete Clock In Portal
     */
    async deleteClockInPortal(requestParameters: DeleteClockInPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteClockInPortalRaw(requestParameters, initOverrides);
    }

    /**
     * List all clock in portals
     * List Clock In Portals
     */
    async listClockInPortalsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListClockInPortalsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clock-in-portal.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clock-in-portals`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListClockInPortalsResponseFromJSON(jsonValue));
    }

    /**
     * List all clock in portals
     * List Clock In Portals
     */
    async listClockInPortals(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListClockInPortalsResponse> {
        const response = await this.listClockInPortalsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show details about a clock in portal
     * Show Clock In Portal
     */
    async showClockInPortalRaw(requestParameters: ShowClockInPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowClockInPortalResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling showClockInPortal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clock-in-portal.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clock-in-portals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowClockInPortalResponseFromJSON(jsonValue));
    }

    /**
     * Show details about a clock in portal
     * Show Clock In Portal
     */
    async showClockInPortal(requestParameters: ShowClockInPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowClockInPortalResponse> {
        const response = await this.showClockInPortalRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a single clock in portal
     * Update Clock In Portal
     */
    async updateClockInPortalRaw(requestParameters: UpdateClockInPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateClockInPortalResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateClockInPortal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.clock-in-portal.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/clock-in-portals/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateClockInPortalDataToJSON(requestParameters.updateClockInPortalData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateClockInPortalResponseFromJSON(jsonValue));
    }

    /**
     * Update a single clock in portal
     * Update Clock In Portal
     */
    async updateClockInPortal(requestParameters: UpdateClockInPortalRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateClockInPortalResponse> {
        const response = await this.updateClockInPortalRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
