/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { Employee } from './Employee';
import {
    EmployeeFromJSON,
    EmployeeFromJSONTyped,
    EmployeeToJSON,
    EmployeeToJSONTyped,
} from './Employee';

/**
 * 
 * @export
 * @interface ListSubordinatesResponse
 */
export interface ListSubordinatesResponse {
    /**
     * 
     * @type {Array<Employee>}
     * @memberof ListSubordinatesResponse
     */
    data: Array<Employee>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListSubordinatesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListSubordinatesResponse interface.
 */
export function instanceOfListSubordinatesResponse(value: object): value is ListSubordinatesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListSubordinatesResponseFromJSON(json: any): ListSubordinatesResponse {
    return ListSubordinatesResponseFromJSONTyped(json, false);
}

export function ListSubordinatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSubordinatesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EmployeeFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListSubordinatesResponseToJSON(json: any): ListSubordinatesResponse {
      return ListSubordinatesResponseToJSONTyped(json, false);
  }

  export function ListSubordinatesResponseToJSONTyped(value?: ListSubordinatesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(EmployeeToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

