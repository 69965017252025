/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePasswordRequestData
 */
export interface UpdatePasswordRequestData {
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequestData
     */
    currentPassword: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePasswordRequestData
     */
    newPassword: string;
}

/**
 * Check if a given object implements the UpdatePasswordRequestData interface.
 */
export function instanceOfUpdatePasswordRequestData(value: object): value is UpdatePasswordRequestData {
    if (!('currentPassword' in value) || value['currentPassword'] === undefined) return false;
    if (!('newPassword' in value) || value['newPassword'] === undefined) return false;
    return true;
}

export function UpdatePasswordRequestDataFromJSON(json: any): UpdatePasswordRequestData {
    return UpdatePasswordRequestDataFromJSONTyped(json, false);
}

export function UpdatePasswordRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePasswordRequestData {
    if (json == null) {
        return json;
    }
    return {
        
        'currentPassword': json['currentPassword'],
        'newPassword': json['newPassword'],
    };
}

  export function UpdatePasswordRequestDataToJSON(json: any): UpdatePasswordRequestData {
      return UpdatePasswordRequestDataToJSONTyped(json, false);
  }

  export function UpdatePasswordRequestDataToJSONTyped(value?: UpdatePasswordRequestData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'currentPassword': value['currentPassword'],
        'newPassword': value['newPassword'],
    };
}

