/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OffersQuota } from './OffersQuota';
import {
    OffersQuotaFromJSON,
    OffersQuotaFromJSONTyped,
    OffersQuotaToJSON,
    OffersQuotaToJSONTyped,
} from './OffersQuota';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyToJSONTyped,
} from './Company';
import type { Offer } from './Offer';
import {
    OfferFromJSON,
    OfferFromJSONTyped,
    OfferToJSON,
    OfferToJSONTyped,
} from './Offer';

/**
 * 
 * @export
 * @interface RedeemedOffer
 */
export interface RedeemedOffer {
    /**
     * 
     * @type {number}
     * @memberof RedeemedOffer
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof RedeemedOffer
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof RedeemedOffer
     */
    offerId: number;
    /**
     * 
     * @type {Company}
     * @memberof RedeemedOffer
     */
    company?: Company;
    /**
     * 
     * @type {Offer}
     * @memberof RedeemedOffer
     */
    offer?: Offer;
    /**
     * 
     * @type {Array<OffersQuota>}
     * @memberof RedeemedOffer
     */
    offersQuotas?: Array<OffersQuota>;
}

/**
 * Check if a given object implements the RedeemedOffer interface.
 */
export function instanceOfRedeemedOffer(value: object): value is RedeemedOffer {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('offerId' in value) || value['offerId'] === undefined) return false;
    return true;
}

export function RedeemedOfferFromJSON(json: any): RedeemedOffer {
    return RedeemedOfferFromJSONTyped(json, false);
}

export function RedeemedOfferFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedeemedOffer {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'offerId': json['offerId'],
        'company': json['company'] == null ? undefined : CompanyFromJSON(json['company']),
        'offer': json['offer'] == null ? undefined : OfferFromJSON(json['offer']),
        'offersQuotas': json['offersQuotas'] == null ? undefined : ((json['offersQuotas'] as Array<any>).map(OffersQuotaFromJSON)),
    };
}

  export function RedeemedOfferToJSON(json: any): RedeemedOffer {
      return RedeemedOfferToJSONTyped(json, false);
  }

  export function RedeemedOfferToJSONTyped(value?: RedeemedOffer | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'offerId': value['offerId'],
        'company': CompanyToJSON(value['company']),
        'offer': OfferToJSON(value['offer']),
        'offersQuotas': value['offersQuotas'] == null ? undefined : ((value['offersQuotas'] as Array<any>).map(OffersQuotaToJSON)),
    };
}

