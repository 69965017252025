import { Router } from 'vue-router';
import spacetime from 'spacetime';
import { useAuthStore } from '../stores/auth.ts';
import { useBootstrapStore } from '../stores/bootstrap.ts';
import { routes } from './routes.ts';
import { usePinAuthStore } from '../stores/pinAuth.ts';
import { i18n } from '../i18n';
import { useAppStore } from '../stores/app.ts';

export const applyGlobalRouterHooks = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    // Save the previous route name to restore it if needed
    const previousTitle = document.title;
    document.title = `Shiftie - Portal ${
      to.name ? `| ${i18n.global.t(`route.${to.name.toString()}`)}` : ''
    }`;
    const appStore = useAppStore();
    const authStore = useAuthStore();
    const pinAuthStore = usePinAuthStore();
    const bootstrapStore = useBootstrapStore();

    if (to.meta.allowsGuest) return next();

    if (
      authStore.token === null ||
      spacetime
        .now()
        .add(2, 'minute')
        .isAfter(spacetime(authStore.tokenExpiry * 1000))
    ) {
      appStore.startLoading();
      // authApi middleware abortAndLogout logs the user out if unauthenticated
      await authStore.refresh().finally(appStore.endLoading);
    }

    // Once authenticated and the route hasn't already fetched the user
    if (!from.name || from.meta.allowsGuest) {
      appStore.startLoading();
      await authStore.fetchAuthenticatedUser().finally(appStore.endLoading);
    }

    // Bootstrap the portal to ensure we always have an up to date version of the company and portal data
    if (!from.name || to.meta.bootstrappedLandingPage) {
      // If we're coming from a page with a temporary employee pin then clear it
      pinAuthStore.clearEmployeeToken();
      appStore.startLoading();
      await bootstrapStore.bootstrap().finally(appStore.endLoading);
    }

    // If the user has just logged in to bootstrap the app's company or portal then allow access
    if (
      from.meta.allowsBootstrap &&
      (to.meta.allowsBootstrap || to.meta.bootstrappedLandingPage)
    ) {
      return next();
    }

    // If the auth hasn't kicked us out already and the portal is bootstrapped
    // Then on a page refresh we should direct to pinEntry
    const { temporaryEmployeeToken, temporaryEmployeeTokenExpiry } =
      pinAuthStore;
    if (
      !to.meta.bootstrappedLandingPage &&
      (temporaryEmployeeToken === null ||
        spacetime
          .now()
          .add(2, 'minute')
          .isAfter(spacetime(temporaryEmployeeTokenExpiry * 1000)))
    ) {
      // Router won't redirect if the route is the same,
      // so we need to restore the title
      if (from.name === routes.pinEntry.name) {
        document.title = previousTitle;
      }
      return next(routes.pinEntry.route());
    }

    // Otherwise allow page access
    next();
  });
};
