import { defineStore } from 'pinia';

interface AppState {
  loading: number;
}

export const useAppStore = defineStore('app', {
  state: (): AppState => ({
    loading: 0,
  }),

  actions: {
    startLoading() {
      this.loading += 1;
    },
    endLoading() {
      this.loading -= 1;
    },
  },
});
