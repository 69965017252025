/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AcknowledgeShiftsData
 */
export interface AcknowledgeShiftsData {
    /**
     * 
     * @type {Array<number>}
     * @memberof AcknowledgeShiftsData
     */
    shiftIds?: Array<number>;
}

/**
 * Check if a given object implements the AcknowledgeShiftsData interface.
 */
export function instanceOfAcknowledgeShiftsData(value: object): value is AcknowledgeShiftsData {
    return true;
}

export function AcknowledgeShiftsDataFromJSON(json: any): AcknowledgeShiftsData {
    return AcknowledgeShiftsDataFromJSONTyped(json, false);
}

export function AcknowledgeShiftsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AcknowledgeShiftsData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftIds': json['shiftIds'] == null ? undefined : json['shiftIds'],
    };
}

  export function AcknowledgeShiftsDataToJSON(json: any): AcknowledgeShiftsData {
      return AcknowledgeShiftsDataToJSONTyped(json, false);
  }

  export function AcknowledgeShiftsDataToJSONTyped(value?: AcknowledgeShiftsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shiftIds': value['shiftIds'],
    };
}

