/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyNotificationChannel
 */
export interface CompanyNotificationChannel {
    /**
     * 
     * @type {string}
     * @memberof CompanyNotificationChannel
     */
    id: string;
    /**
     * 
     * @type {number}
     * @memberof CompanyNotificationChannel
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof CompanyNotificationChannel
     */
    group: CompanyNotificationChannelGroupEnum;
    /**
     * 
     * @type {string}
     * @memberof CompanyNotificationChannel
     */
    channel: CompanyNotificationChannelChannelEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyNotificationChannel
     */
    enabled: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CompanyNotificationChannelGroupEnum {
    Absence = 'Absence',
    Billing = 'Billing',
    Document = 'Document',
    Leave = 'Leave',
    Shift = 'Shift',
    Timesheet = 'Timesheet'
}
/**
* @export
* @enum {string}
*/
export enum CompanyNotificationChannelChannelEnum {
    Email = 'Email',
    Push = 'Push',
    Sms = 'Sms'
}


/**
 * Check if a given object implements the CompanyNotificationChannel interface.
 */
export function instanceOfCompanyNotificationChannel(value: object): value is CompanyNotificationChannel {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('group' in value) || value['group'] === undefined) return false;
    if (!('channel' in value) || value['channel'] === undefined) return false;
    if (!('enabled' in value) || value['enabled'] === undefined) return false;
    return true;
}

export function CompanyNotificationChannelFromJSON(json: any): CompanyNotificationChannel {
    return CompanyNotificationChannelFromJSONTyped(json, false);
}

export function CompanyNotificationChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyNotificationChannel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'group': json['group'],
        'channel': json['channel'],
        'enabled': json['enabled'],
    };
}

  export function CompanyNotificationChannelToJSON(json: any): CompanyNotificationChannel {
      return CompanyNotificationChannelToJSONTyped(json, false);
  }

  export function CompanyNotificationChannelToJSONTyped(value?: CompanyNotificationChannel | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'group': value['group'],
        'channel': value['channel'],
        'enabled': value['enabled'],
    };
}

