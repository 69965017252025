/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    LocationToJSONTyped,
} from './Location';

/**
 * 
 * @export
 * @interface ShiftArea
 */
export interface ShiftArea {
    /**
     * 
     * @type {number}
     * @memberof ShiftArea
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ShiftArea
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof ShiftArea
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftArea
     */
    code: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShiftArea
     */
    colour: string;
    /**
     * 
     * @type {string}
     * @memberof ShiftArea
     */
    status: ShiftAreaStatusEnum;
    /**
     * 
     * @type {Array<Location>}
     * @memberof ShiftArea
     */
    locations?: Array<Location>;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShiftArea
     */
    locationIds: Array<number>;
}

/**
* @export
* @enum {string}
*/
export enum ShiftAreaStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}


/**
 * Check if a given object implements the ShiftArea interface.
 */
export function instanceOfShiftArea(value: object): value is ShiftArea {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('code' in value) || value['code'] === undefined) return false;
    if (!('colour' in value) || value['colour'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('locationIds' in value) || value['locationIds'] === undefined) return false;
    return true;
}

export function ShiftAreaFromJSON(json: any): ShiftArea {
    return ShiftAreaFromJSONTyped(json, false);
}

export function ShiftAreaFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShiftArea {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'code': json['code'],
        'colour': json['colour'],
        'status': json['status'],
        'locations': json['locations'] == null ? undefined : ((json['locations'] as Array<any>).map(LocationFromJSON)),
        'locationIds': json['locationIds'],
    };
}

  export function ShiftAreaToJSON(json: any): ShiftArea {
      return ShiftAreaToJSONTyped(json, false);
  }

  export function ShiftAreaToJSONTyped(value?: ShiftArea | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'code': value['code'],
        'colour': value['colour'],
        'status': value['status'],
        'locations': value['locations'] == null ? undefined : ((value['locations'] as Array<any>).map(LocationToJSON)),
        'locationIds': value['locationIds'],
    };
}

