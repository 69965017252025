/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateClockingSettingsData
 */
export interface UpdateClockingSettingsData {
    /**
     * 
     * @type {number}
     * @memberof UpdateClockingSettingsData
     */
    earlyClockInPeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateClockingSettingsData
     */
    clockInGracePeriod?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateClockingSettingsData
     */
    autoApprovalThresholdInMinutes?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateClockingSettingsData
     */
    employeesCanEditTimeEntries?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateClockingSettingsData
     */
    autoClockOutDelayPeriod?: number;
}

/**
 * Check if a given object implements the UpdateClockingSettingsData interface.
 */
export function instanceOfUpdateClockingSettingsData(value: object): value is UpdateClockingSettingsData {
    return true;
}

export function UpdateClockingSettingsDataFromJSON(json: any): UpdateClockingSettingsData {
    return UpdateClockingSettingsDataFromJSONTyped(json, false);
}

export function UpdateClockingSettingsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClockingSettingsData {
    if (json == null) {
        return json;
    }
    return {
        
        'earlyClockInPeriod': json['earlyClockInPeriod'] == null ? undefined : json['earlyClockInPeriod'],
        'clockInGracePeriod': json['clockInGracePeriod'] == null ? undefined : json['clockInGracePeriod'],
        'autoApprovalThresholdInMinutes': json['autoApprovalThresholdInMinutes'] == null ? undefined : json['autoApprovalThresholdInMinutes'],
        'employeesCanEditTimeEntries': json['employeesCanEditTimeEntries'] == null ? undefined : json['employeesCanEditTimeEntries'],
        'autoClockOutDelayPeriod': json['autoClockOutDelayPeriod'] == null ? undefined : json['autoClockOutDelayPeriod'],
    };
}

  export function UpdateClockingSettingsDataToJSON(json: any): UpdateClockingSettingsData {
      return UpdateClockingSettingsDataToJSONTyped(json, false);
  }

  export function UpdateClockingSettingsDataToJSONTyped(value?: UpdateClockingSettingsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'earlyClockInPeriod': value['earlyClockInPeriod'],
        'clockInGracePeriod': value['clockInGracePeriod'],
        'autoApprovalThresholdInMinutes': value['autoApprovalThresholdInMinutes'],
        'employeesCanEditTimeEntries': value['employeesCanEditTimeEntries'],
        'autoClockOutDelayPeriod': value['autoClockOutDelayPeriod'],
    };
}

