import { createRouter, createWebHistory, RouteLocationRaw } from 'vue-router';
import { OurRouteConfig, OurRouteRecord, routes } from './routes.ts';
import { applyGlobalRouterHooks } from './globalHooks.ts';

const router = createRouter({
  history: createWebHistory(),
  routes: [],
  scrollBehavior() {
    return { top: 0 };
  },
});

// Make a copy of the routes that we can start to form into the shape that vue-router wants
export const copyOfRoutes = Object.keys(routes as OurRouteConfig).reduce(
  (copy: OurRouteConfig, routeName) => {
    copy[routeName] = { ...routes[routeName as keyof typeof routes] };
    return copy;
  },
  {},
);

Object.values(copyOfRoutes).forEach((v: OurRouteRecord) => {
  if (v.childOf !== undefined) {
    const parentName = v.childOf();

    copyOfRoutes[parentName].children ??= [];
    copyOfRoutes[parentName].children?.push(v);
  }
});

// Remove all root entries that are children
// It's now in a format that is acceptable to vue-router
const routesForRouter = Object.values(copyOfRoutes).filter(
  (v) => !('childOf' in v),
);

routesForRouter.forEach((r) => router.addRoute(r));

// Apply router hooks
applyGlobalRouterHooks(router);

export const redirect = (location: RouteLocationRaw) => router.push(location);

export default router;
