/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkPattern
 */
export interface WorkPattern {
    /**
     * 
     * @type {number}
     * @memberof WorkPattern
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof WorkPattern
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof WorkPattern
     */
    name: string;
    /**
     * An array representing which days of the week are working days. 0 = Sunday, 6 = Saturday.
     * @type {Array<boolean>}
     * @memberof WorkPattern
     */
    pattern: Array<boolean>;
    /**
     * 
     * @type {boolean}
     * @memberof WorkPattern
     */
    isPrimary: boolean;
}

/**
 * Check if a given object implements the WorkPattern interface.
 */
export function instanceOfWorkPattern(value: object): value is WorkPattern {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('pattern' in value) || value['pattern'] === undefined) return false;
    if (!('isPrimary' in value) || value['isPrimary'] === undefined) return false;
    return true;
}

export function WorkPatternFromJSON(json: any): WorkPattern {
    return WorkPatternFromJSONTyped(json, false);
}

export function WorkPatternFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkPattern {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'name': json['name'],
        'pattern': json['pattern'],
        'isPrimary': json['isPrimary'],
    };
}

  export function WorkPatternToJSON(json: any): WorkPattern {
      return WorkPatternToJSONTyped(json, false);
  }

  export function WorkPatternToJSONTyped(value?: WorkPattern | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'name': value['name'],
        'pattern': value['pattern'],
        'isPrimary': value['isPrimary'],
    };
}

