/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeaveSettingsData
 */
export interface UpdateLeaveSettingsData {
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveSettingsData
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveSettingsData
     */
    enableLeaveRequests?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveSettingsData
     */
    employeesCanLogOwnUnavailability?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveSettingsData
     */
    defaultLeaveApproverId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveSettingsData
     */
    unavailabilityNoticePeriod?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveSettingsData
     */
    defaultEntitlement?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveSettingsData
     */
    carryOver?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveSettingsData
     */
    negativeCarryOver?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveSettingsData
     */
    carryOverWindow?: number | null;
}

/**
 * Check if a given object implements the UpdateLeaveSettingsData interface.
 */
export function instanceOfUpdateLeaveSettingsData(value: object): value is UpdateLeaveSettingsData {
    return true;
}

export function UpdateLeaveSettingsDataFromJSON(json: any): UpdateLeaveSettingsData {
    return UpdateLeaveSettingsDataFromJSONTyped(json, false);
}

export function UpdateLeaveSettingsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveSettingsData {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'enableLeaveRequests': json['enableLeaveRequests'] == null ? undefined : json['enableLeaveRequests'],
        'employeesCanLogOwnUnavailability': json['employeesCanLogOwnUnavailability'] == null ? undefined : json['employeesCanLogOwnUnavailability'],
        'defaultLeaveApproverId': json['defaultLeaveApproverId'] == null ? undefined : json['defaultLeaveApproverId'],
        'unavailabilityNoticePeriod': json['unavailabilityNoticePeriod'] == null ? undefined : json['unavailabilityNoticePeriod'],
        'defaultEntitlement': json['defaultEntitlement'] == null ? undefined : json['defaultEntitlement'],
        'carryOver': json['carryOver'] == null ? undefined : json['carryOver'],
        'negativeCarryOver': json['negativeCarryOver'] == null ? undefined : json['negativeCarryOver'],
        'carryOverWindow': json['carryOverWindow'] == null ? undefined : json['carryOverWindow'],
    };
}

  export function UpdateLeaveSettingsDataToJSON(json: any): UpdateLeaveSettingsData {
      return UpdateLeaveSettingsDataToJSONTyped(json, false);
  }

  export function UpdateLeaveSettingsDataToJSONTyped(value?: UpdateLeaveSettingsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'enableLeaveRequests': value['enableLeaveRequests'],
        'employeesCanLogOwnUnavailability': value['employeesCanLogOwnUnavailability'],
        'defaultLeaveApproverId': value['defaultLeaveApproverId'],
        'unavailabilityNoticePeriod': value['unavailabilityNoticePeriod'],
        'defaultEntitlement': value['defaultEntitlement'],
        'carryOver': value['carryOver'],
        'negativeCarryOver': value['negativeCarryOver'],
        'carryOverWindow': value['carryOverWindow'],
    };
}

