import spacetime from 'spacetime';
import { isValidIsoDateString } from '../../util/dateFunctions';

const partsToIsoString = (year: number, month: number, day: number) =>
  `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;

/**
 * This is our own temporary stand-in for representing calendar dates, until Temporal.PlainDate has landed in browsers.
 * It mostly follows the spec, but with a couple of convenience functions, to help us migrate in the meantime:
 *    - fromDate()
 *    - toDate()
 */
export class PlainDate {
  readonly _year: number;

  readonly _month: number;

  readonly _day: number;

  constructor(year: number, month: number, day: number) {
    const isoString = partsToIsoString(year, month, day);
    if (!isValidIsoDateString(isoString)) {
      throw new Error(`${isoString} is an invalid date`);
    }
    this._year = year;
    this._month = month;
    this._day = day;
  }

  /** Accepts an ISO date string in the format YYYY-MM-DD and returns a PlainDate object */
  static from(isoString: string): PlainDate {
    if (!isValidIsoDateString(isoString)) {
      throw new Error(`ISO date string '${isoString}' is invalid`);
    }
    const parts = isoString.split('-').map((p) => parseInt(p, 10));
    return new PlainDate(parts[0], parts[1], parts[2]);
  }

  /** Accepts an ISO date string in the format YYYY-MM-DD and returns a PlainDate object */
  static fromDate(date: Date, timezone: string): PlainDate {
    const isoString = spacetime(date, timezone).format('iso-short');
    return PlainDate.from(isoString);
  }

  /** Returns a native Date object representing the start of the day in the given timezone */
  toDate(timezone: string): Date {
    return spacetime(this.toString(), timezone).toNativeDate();
  }

  /** Returns an ISO date string in the format YYYY-MM-DD */
  toJSON(): string {
    return this.toString();
  }

  /** Returns an ISO date string in the format YYYY-MM-DD */
  toString(): string {
    return partsToIsoString(this._year, this._month, this._day);
  }
}
