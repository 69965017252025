/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    LocationToJSONTyped,
} from './Location';

/**
 * 
 * @export
 * @interface CreateLocationResponse
 */
export interface CreateLocationResponse {
    /**
     * 
     * @type {Location}
     * @memberof CreateLocationResponse
     */
    data: Location;
}

/**
 * Check if a given object implements the CreateLocationResponse interface.
 */
export function instanceOfCreateLocationResponse(value: object): value is CreateLocationResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateLocationResponseFromJSON(json: any): CreateLocationResponse {
    return CreateLocationResponseFromJSONTyped(json, false);
}

export function CreateLocationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLocationResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': LocationFromJSON(json['data']),
    };
}

  export function CreateLocationResponseToJSON(json: any): CreateLocationResponse {
      return CreateLocationResponseToJSONTyped(json, false);
  }

  export function CreateLocationResponseToJSONTyped(value?: CreateLocationResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': LocationToJSON(value['data']),
    };
}

