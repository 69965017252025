/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClockInEvent
 */
export interface ClockInEvent {
    /**
     * 
     * @type {number}
     * @memberof ClockInEvent
     */
    companyId: number;
    /**
     * Which timesheet entry this event relates to.
     * @type {number}
     * @memberof ClockInEvent
     */
    timesheetEntryId: number;
    /**
     * Which timesheet break this event relates to (if applicable).
     * @type {number}
     * @memberof ClockInEvent
     */
    timesheetBreakId: number | null;
    /**
     * The id of the employee who initiated the event.
     * @type {number}
     * @memberof ClockInEvent
     */
    actionedById: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClockInEvent
     */
    type: ClockInEventTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ClockInEvent
     */
    device: ClockInEventDeviceEnum;
    /**
     * 
     * @type {string}
     * @memberof ClockInEvent
     */
    origin: ClockInEventOriginEnum;
    /**
     * Indicates whether ths action was system generated, e.g. for an automatic clock-out.
     * @type {boolean}
     * @memberof ClockInEvent
     */
    isSystemGenerated: boolean;
}

/**
* @export
* @enum {string}
*/
export enum ClockInEventTypeEnum {
    BreakEnd = 'Break End',
    BreakStart = 'Break Start',
    ClockIn = 'Clock In',
    ClockOut = 'Clock Out'
}
/**
* @export
* @enum {string}
*/
export enum ClockInEventDeviceEnum {
    Api = 'API',
    Desktop = 'Desktop',
    Mobile = 'Mobile',
    Tablet = 'Tablet'
}
/**
* @export
* @enum {string}
*/
export enum ClockInEventOriginEnum {
    Admin = 'Admin',
    Api = 'API',
    Frontend = 'Frontend',
    Portal = 'Portal'
}


/**
 * Check if a given object implements the ClockInEvent interface.
 */
export function instanceOfClockInEvent(value: object): value is ClockInEvent {
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('timesheetEntryId' in value) || value['timesheetEntryId'] === undefined) return false;
    if (!('timesheetBreakId' in value) || value['timesheetBreakId'] === undefined) return false;
    if (!('actionedById' in value) || value['actionedById'] === undefined) return false;
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('device' in value) || value['device'] === undefined) return false;
    if (!('origin' in value) || value['origin'] === undefined) return false;
    if (!('isSystemGenerated' in value) || value['isSystemGenerated'] === undefined) return false;
    return true;
}

export function ClockInEventFromJSON(json: any): ClockInEvent {
    return ClockInEventFromJSONTyped(json, false);
}

export function ClockInEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockInEvent {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'timesheetEntryId': json['timesheetEntryId'],
        'timesheetBreakId': json['timesheetBreakId'],
        'actionedById': json['actionedById'],
        'type': json['type'],
        'device': json['device'],
        'origin': json['origin'],
        'isSystemGenerated': json['isSystemGenerated'],
    };
}

  export function ClockInEventToJSON(json: any): ClockInEvent {
      return ClockInEventToJSONTyped(json, false);
  }

  export function ClockInEventToJSONTyped(value?: ClockInEvent | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'companyId': value['companyId'],
        'timesheetEntryId': value['timesheetEntryId'],
        'timesheetBreakId': value['timesheetBreakId'],
        'actionedById': value['actionedById'],
        'type': value['type'],
        'device': value['device'],
        'origin': value['origin'],
        'isSystemGenerated': value['isSystemGenerated'],
    };
}

