/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CompanySetting } from './CompanySetting';
import {
    CompanySettingFromJSON,
    CompanySettingFromJSONTyped,
    CompanySettingToJSON,
    CompanySettingToJSONTyped,
} from './CompanySetting';

/**
 * 
 * @export
 * @interface ShowCompanySettingsResponse
 */
export interface ShowCompanySettingsResponse {
    /**
     * 
     * @type {CompanySetting}
     * @memberof ShowCompanySettingsResponse
     */
    data: CompanySetting;
}

/**
 * Check if a given object implements the ShowCompanySettingsResponse interface.
 */
export function instanceOfShowCompanySettingsResponse(value: object): value is ShowCompanySettingsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function ShowCompanySettingsResponseFromJSON(json: any): ShowCompanySettingsResponse {
    return ShowCompanySettingsResponseFromJSONTyped(json, false);
}

export function ShowCompanySettingsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShowCompanySettingsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': CompanySettingFromJSON(json['data']),
    };
}

  export function ShowCompanySettingsResponseToJSON(json: any): ShowCompanySettingsResponse {
      return ShowCompanySettingsResponseToJSONTyped(json, false);
  }

  export function ShowCompanySettingsResponseToJSONTyped(value?: ShowCompanySettingsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': CompanySettingToJSON(value['data']),
    };
}

