/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RefreshData
 */
export interface RefreshData {
    /**
     * 
     * @type {string}
     * @memberof RefreshData
     */
    refreshToken?: string;
}

/**
 * Check if a given object implements the RefreshData interface.
 */
export function instanceOfRefreshData(value: object): value is RefreshData {
    return true;
}

export function RefreshDataFromJSON(json: any): RefreshData {
    return RefreshDataFromJSONTyped(json, false);
}

export function RefreshDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RefreshData {
    if (json == null) {
        return json;
    }
    return {
        
        'refreshToken': json['refreshToken'] == null ? undefined : json['refreshToken'],
    };
}

  export function RefreshDataToJSON(json: any): RefreshData {
      return RefreshDataToJSONTyped(json, false);
  }

  export function RefreshDataToJSONTyped(value?: RefreshData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'refreshToken': value['refreshToken'],
    };
}

