/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateScheduledBreakData } from './CreateScheduledBreakData';
import {
    CreateScheduledBreakDataFromJSON,
    CreateScheduledBreakDataFromJSONTyped,
    CreateScheduledBreakDataToJSON,
    CreateScheduledBreakDataToJSONTyped,
} from './CreateScheduledBreakData';

/**
 * 
 * @export
 * @interface BulkCreateShiftsShiftData
 */
export interface BulkCreateShiftsShiftData {
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftsShiftData
     */
    scheduleId?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftsShiftData
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftsShiftData
     */
    employeeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftsShiftData
     */
    jobRoleId?: number;
    /**
     * Whether to offer the shift to all eligible employees
     * @type {boolean}
     * @memberof BulkCreateShiftsShiftData
     */
    open?: boolean;
    /**
     * Whether the system can auto-assign employees when they respond to an open shift
     * @type {boolean}
     * @memberof BulkCreateShiftsShiftData
     */
    requiresApproval?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof BulkCreateShiftsShiftData
     */
    startsAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BulkCreateShiftsShiftData
     */
    endsAt?: Date;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof BulkCreateShiftsShiftData
     */
    showEndTime?: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof BulkCreateShiftsShiftData
     */
    showJobRole?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkCreateShiftsShiftData
     */
    tagIds?: Array<number>;
    /**
     * 
     * @type {Array<CreateScheduledBreakData>}
     * @memberof BulkCreateShiftsShiftData
     */
    scheduledBreaks?: Array<CreateScheduledBreakData>;
    /**
     * 
     * @type {string}
     * @memberof BulkCreateShiftsShiftData
     */
    notes?: string | null;
}

/**
 * Check if a given object implements the BulkCreateShiftsShiftData interface.
 */
export function instanceOfBulkCreateShiftsShiftData(value: object): value is BulkCreateShiftsShiftData {
    return true;
}

export function BulkCreateShiftsShiftDataFromJSON(json: any): BulkCreateShiftsShiftData {
    return BulkCreateShiftsShiftDataFromJSONTyped(json, false);
}

export function BulkCreateShiftsShiftDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateShiftsShiftData {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduleId': json['scheduleId'] == null ? undefined : json['scheduleId'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
        'jobRoleId': json['jobRoleId'] == null ? undefined : json['jobRoleId'],
        'open': json['open'] == null ? undefined : json['open'],
        'requiresApproval': json['requiresApproval'] == null ? undefined : json['requiresApproval'],
        'startsAt': json['startsAt'] == null ? undefined : (new Date(json['startsAt'])),
        'endsAt': json['endsAt'] == null ? undefined : (new Date(json['endsAt'])),
        'showEndTime': json['showEndTime'] == null ? undefined : json['showEndTime'],
        'showJobRole': json['showJobRole'] == null ? undefined : json['showJobRole'],
        'tagIds': json['tagIds'] == null ? undefined : json['tagIds'],
        'scheduledBreaks': json['scheduledBreaks'] == null ? undefined : ((json['scheduledBreaks'] as Array<any>).map(CreateScheduledBreakDataFromJSON)),
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

  export function BulkCreateShiftsShiftDataToJSON(json: any): BulkCreateShiftsShiftData {
      return BulkCreateShiftsShiftDataToJSONTyped(json, false);
  }

  export function BulkCreateShiftsShiftDataToJSONTyped(value?: BulkCreateShiftsShiftData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'scheduleId': value['scheduleId'],
        'locationId': value['locationId'],
        'employeeId': value['employeeId'],
        'jobRoleId': value['jobRoleId'],
        'open': value['open'],
        'requiresApproval': value['requiresApproval'],
        'startsAt': value['startsAt'] == null ? undefined : ((value['startsAt']).toISOString()),
        'endsAt': value['endsAt'] == null ? undefined : ((value['endsAt']).toISOString()),
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'tagIds': value['tagIds'],
        'scheduledBreaks': value['scheduledBreaks'] == null ? undefined : ((value['scheduledBreaks'] as Array<any>).map(CreateScheduledBreakDataToJSON)),
        'notes': value['notes'],
    };
}

