/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ClockInPortal } from './ClockInPortal';
import {
    ClockInPortalFromJSON,
    ClockInPortalFromJSONTyped,
    ClockInPortalToJSON,
    ClockInPortalToJSONTyped,
} from './ClockInPortal';

/**
 * 
 * @export
 * @interface UpdateClockInPortalResponse
 */
export interface UpdateClockInPortalResponse {
    /**
     * 
     * @type {ClockInPortal}
     * @memberof UpdateClockInPortalResponse
     */
    data: ClockInPortal;
}

/**
 * Check if a given object implements the UpdateClockInPortalResponse interface.
 */
export function instanceOfUpdateClockInPortalResponse(value: object): value is UpdateClockInPortalResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateClockInPortalResponseFromJSON(json: any): UpdateClockInPortalResponse {
    return UpdateClockInPortalResponseFromJSONTyped(json, false);
}

export function UpdateClockInPortalResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateClockInPortalResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ClockInPortalFromJSON(json['data']),
    };
}

  export function UpdateClockInPortalResponseToJSON(json: any): UpdateClockInPortalResponse {
      return UpdateClockInPortalResponseToJSONTyped(json, false);
  }

  export function UpdateClockInPortalResponseToJSONTyped(value?: UpdateClockInPortalResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ClockInPortalToJSON(value['data']),
    };
}

