/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface UpdateEmployeePersonalDetailsData
 */
export interface UpdateEmployeePersonalDetailsData {
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    emergencyContactName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    emergencyContactPhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    address1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    address2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    region?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    country?: string | null;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    dateOfBirth?: ShiftiePlainDate | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    gender?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    nationality?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    ethnicity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    maritalStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    nationalInsuranceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    drivingLicenceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    passportNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    notes?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateEmployeePersonalDetailsData
     */
    medicalDetails?: string | null;
}

/**
 * Check if a given object implements the UpdateEmployeePersonalDetailsData interface.
 */
export function instanceOfUpdateEmployeePersonalDetailsData(value: object): value is UpdateEmployeePersonalDetailsData {
    return true;
}

export function UpdateEmployeePersonalDetailsDataFromJSON(json: any): UpdateEmployeePersonalDetailsData {
    return UpdateEmployeePersonalDetailsDataFromJSONTyped(json, false);
}

export function UpdateEmployeePersonalDetailsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeePersonalDetailsData {
    if (json == null) {
        return json;
    }
    return {
        
        'emergencyContactName': json['emergencyContactName'] == null ? undefined : json['emergencyContactName'],
        'emergencyContactPhoneNumber': json['emergencyContactPhoneNumber'] == null ? undefined : json['emergencyContactPhoneNumber'],
        'address1': json['address1'] == null ? undefined : json['address1'],
        'address2': json['address2'] == null ? undefined : json['address2'],
        'city': json['city'] == null ? undefined : json['city'],
        'postalCode': json['postalCode'] == null ? undefined : json['postalCode'],
        'region': json['region'] == null ? undefined : json['region'],
        'country': json['country'] == null ? undefined : json['country'],
        'dateOfBirth': json['dateOfBirth'] == null ? undefined : ShiftiePlainDateFromJSON(json['dateOfBirth']),
        'gender': json['gender'] == null ? undefined : json['gender'],
        'nationality': json['nationality'] == null ? undefined : json['nationality'],
        'ethnicity': json['ethnicity'] == null ? undefined : json['ethnicity'],
        'maritalStatus': json['maritalStatus'] == null ? undefined : json['maritalStatus'],
        'nationalInsuranceNumber': json['nationalInsuranceNumber'] == null ? undefined : json['nationalInsuranceNumber'],
        'drivingLicenceNumber': json['drivingLicenceNumber'] == null ? undefined : json['drivingLicenceNumber'],
        'passportNumber': json['passportNumber'] == null ? undefined : json['passportNumber'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'medicalDetails': json['medicalDetails'] == null ? undefined : json['medicalDetails'],
    };
}

  export function UpdateEmployeePersonalDetailsDataToJSON(json: any): UpdateEmployeePersonalDetailsData {
      return UpdateEmployeePersonalDetailsDataToJSONTyped(json, false);
  }

  export function UpdateEmployeePersonalDetailsDataToJSONTyped(value?: UpdateEmployeePersonalDetailsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'emergencyContactName': value['emergencyContactName'],
        'emergencyContactPhoneNumber': value['emergencyContactPhoneNumber'],
        'address1': value['address1'],
        'address2': value['address2'],
        'city': value['city'],
        'postalCode': value['postalCode'],
        'region': value['region'],
        'country': value['country'],
        'dateOfBirth': ShiftiePlainDateToJSON(value['dateOfBirth']),
        'gender': value['gender'],
        'nationality': value['nationality'],
        'ethnicity': value['ethnicity'],
        'maritalStatus': value['maritalStatus'],
        'nationalInsuranceNumber': value['nationalInsuranceNumber'],
        'drivingLicenceNumber': value['drivingLicenceNumber'],
        'passportNumber': value['passportNumber'],
        'notes': value['notes'],
        'medicalDetails': value['medicalDetails'],
    };
}

