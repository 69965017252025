import {
  BaseAPI as AuthBaseAPI,
  Configuration as AuthConfiguration,
  ConfigurationParameters as AuthConfigurationParameters,
  FetchParams as AuthFetchParams,
  HTTPQuery as AuthHttpQuery,
  Middleware as AuthMiddleware,
  RequestContext as AuthRequestContext,
  ResponseContext as AuthResponseContext,
} from '../../sdk/auth';
import {
  BaseAPI as V1BaseAPI,
  Configuration as V1Configuration,
  ConfigurationParameters as V1ConfigurationParameters,
  FetchParams as V1FetchParams,
  HTTPQuery as V1HttpQuery,
  Middleware as V1Middleware,
  RequestContext as V1RequestContext,
  ResponseContext as V1ResponseContext,
} from '../../sdk/v1';

/**
 * API version types
 */
export enum ApiVersion {
  Auth = 'auth',
  V1 = 'v1',
}

export type ApiVersionTypeMap = {
  [ApiVersion.Auth]: {
    BaseApi: AuthBaseAPI;
    Configuration: AuthConfiguration;
    ConfigurationParameters: AuthConfigurationParameters;
    FetchParams: AuthFetchParams;
    HttpQuery: AuthHttpQuery;
    Middleware: AuthMiddleware;
    RequestContext: AuthRequestContext;
    ResponseContext: AuthResponseContext;
  };
  [ApiVersion.V1]: {
    BaseApi: V1BaseAPI;
    Configuration: V1Configuration;
    ConfigurationParameters: V1ConfigurationParameters;
    FetchParams: V1FetchParams;
    HttpQuery: V1HttpQuery;
    Middleware: V1Middleware;
    RequestContext: V1RequestContext;
    ResponseContext: V1ResponseContext;
  };
};

// Only to be used when you need to access mapped class definitions, e.g. for passing typed parameters to another
// function. It should never be used for inline type definitions, mainly because it requires the constant use of
// `typeof`. In these use cases, the above type map should be used instead.
export const ApiVersionClassMap: {
  [a in ApiVersion]: { BaseApi: any; Configuration: any };
} = {
  [ApiVersion.Auth]: {
    BaseApi: AuthBaseAPI,
    Configuration: AuthConfiguration,
  },
  [ApiVersion.V1]: {
    BaseApi: V1BaseAPI,
    Configuration: V1Configuration,
  },
};

/**
 * Middleware types
 */
export type middlewareCreator = <
  A extends ApiVersion,
>() => ApiVersionTypeMap[A]['Middleware'];
