/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCompanySettingsData
 */
export interface UpdateCompanySettingsData {
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanySettingsData
     */
    logoId?: number | null;
    /**
     * Modifying this value requires the ManageProtectedSettings permission in addition to other endpoint defined permissions.
     * @type {number}
     * @memberof UpdateCompanySettingsData
     */
    accountOwnerId?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanySettingsData
     */
    primaryContactId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanySettingsData
     */
    allowOvertime?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanySettingsData
     */
    allowTimeOff?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanySettingsData
     */
    employeesCanLogOwnAbsences?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanySettingsData
     */
    showApprovedTimeOff?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanySettingsData
     */
    startOfWeek?: UpdateCompanySettingsDataStartOfWeekEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanySettingsData
     */
    timeFormat?: UpdateCompanySettingsDataTimeFormatEnum;
    /**
     * The default number of hours taken from an employee's leave allowance for a day's worth of leave
     * @type {number}
     * @memberof UpdateCompanySettingsData
     */
    defaultLeaveHoursUsedPerDay?: number;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanySettingsData
     */
    overtimeDailyThreshold?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanySettingsData
     */
    overtimeWeeklyThreshold?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanySettingsData
     */
    doubleOvertimeDailyThreshold?: number | null;
    /**
     * An ISO 4217 currency code.
     * @type {string}
     * @memberof UpdateCompanySettingsData
     */
    currencyCode?: string;
}

/**
* @export
* @enum {string}
*/
export enum UpdateCompanySettingsDataStartOfWeekEnum {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday'
}
/**
* @export
* @enum {string}
*/
export enum UpdateCompanySettingsDataTimeFormatEnum {
    _12 = '12',
    _24 = '24'
}


/**
 * Check if a given object implements the UpdateCompanySettingsData interface.
 */
export function instanceOfUpdateCompanySettingsData(value: object): value is UpdateCompanySettingsData {
    return true;
}

export function UpdateCompanySettingsDataFromJSON(json: any): UpdateCompanySettingsData {
    return UpdateCompanySettingsDataFromJSONTyped(json, false);
}

export function UpdateCompanySettingsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateCompanySettingsData {
    if (json == null) {
        return json;
    }
    return {
        
        'logoId': json['logoId'] == null ? undefined : json['logoId'],
        'accountOwnerId': json['accountOwnerId'] == null ? undefined : json['accountOwnerId'],
        'primaryContactId': json['primaryContactId'] == null ? undefined : json['primaryContactId'],
        'allowOvertime': json['allowOvertime'] == null ? undefined : json['allowOvertime'],
        'allowTimeOff': json['allowTimeOff'] == null ? undefined : json['allowTimeOff'],
        'employeesCanLogOwnAbsences': json['employeesCanLogOwnAbsences'] == null ? undefined : json['employeesCanLogOwnAbsences'],
        'showApprovedTimeOff': json['showApprovedTimeOff'] == null ? undefined : json['showApprovedTimeOff'],
        'startOfWeek': json['startOfWeek'] == null ? undefined : json['startOfWeek'],
        'timeFormat': json['timeFormat'] == null ? undefined : json['timeFormat'],
        'defaultLeaveHoursUsedPerDay': json['defaultLeaveHoursUsedPerDay'] == null ? undefined : json['defaultLeaveHoursUsedPerDay'],
        'overtimeDailyThreshold': json['overtimeDailyThreshold'] == null ? undefined : json['overtimeDailyThreshold'],
        'overtimeWeeklyThreshold': json['overtimeWeeklyThreshold'] == null ? undefined : json['overtimeWeeklyThreshold'],
        'doubleOvertimeDailyThreshold': json['doubleOvertimeDailyThreshold'] == null ? undefined : json['doubleOvertimeDailyThreshold'],
        'currencyCode': json['currencyCode'] == null ? undefined : json['currencyCode'],
    };
}

  export function UpdateCompanySettingsDataToJSON(json: any): UpdateCompanySettingsData {
      return UpdateCompanySettingsDataToJSONTyped(json, false);
  }

  export function UpdateCompanySettingsDataToJSONTyped(value?: UpdateCompanySettingsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'logoId': value['logoId'],
        'accountOwnerId': value['accountOwnerId'],
        'primaryContactId': value['primaryContactId'],
        'allowOvertime': value['allowOvertime'],
        'allowTimeOff': value['allowTimeOff'],
        'employeesCanLogOwnAbsences': value['employeesCanLogOwnAbsences'],
        'showApprovedTimeOff': value['showApprovedTimeOff'],
        'startOfWeek': value['startOfWeek'],
        'timeFormat': value['timeFormat'],
        'defaultLeaveHoursUsedPerDay': value['defaultLeaveHoursUsedPerDay'],
        'overtimeDailyThreshold': value['overtimeDailyThreshold'],
        'overtimeWeeklyThreshold': value['overtimeWeeklyThreshold'],
        'doubleOvertimeDailyThreshold': value['doubleOvertimeDailyThreshold'],
        'currencyCode': value['currencyCode'],
    };
}

