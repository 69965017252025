/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanySimplified
 */
export interface CompanySimplified {
    /**
     * 
     * @type {string}
     * @memberof CompanySimplified
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompanySimplified
     */
    ref: string;
}

/**
 * Check if a given object implements the CompanySimplified interface.
 */
export function instanceOfCompanySimplified(value: object): value is CompanySimplified {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('ref' in value) || value['ref'] === undefined) return false;
    return true;
}

export function CompanySimplifiedFromJSON(json: any): CompanySimplified {
    return CompanySimplifiedFromJSONTyped(json, false);
}

export function CompanySimplifiedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanySimplified {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'ref': json['ref'],
    };
}

  export function CompanySimplifiedToJSON(json: any): CompanySimplified {
      return CompanySimplifiedToJSONTyped(json, false);
  }

  export function CompanySimplifiedToJSONTyped(value?: CompanySimplified | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'ref': value['ref'],
    };
}

