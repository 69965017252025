/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';

/**
 * 
 * @export
 * @interface UpdateJobRoleResponse
 */
export interface UpdateJobRoleResponse {
    /**
     * 
     * @type {JobRole}
     * @memberof UpdateJobRoleResponse
     */
    data: JobRole;
}

/**
 * Check if a given object implements the UpdateJobRoleResponse interface.
 */
export function instanceOfUpdateJobRoleResponse(value: object): value is UpdateJobRoleResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UpdateJobRoleResponseFromJSON(json: any): UpdateJobRoleResponse {
    return UpdateJobRoleResponseFromJSONTyped(json, false);
}

export function UpdateJobRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateJobRoleResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': JobRoleFromJSON(json['data']),
    };
}

  export function UpdateJobRoleResponseToJSON(json: any): UpdateJobRoleResponse {
      return UpdateJobRoleResponseToJSONTyped(json, false);
  }

  export function UpdateJobRoleResponseToJSONTyped(value?: UpdateJobRoleResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': JobRoleToJSON(value['data']),
    };
}

