/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { Invoice } from './Invoice';
import {
    InvoiceFromJSON,
    InvoiceFromJSONTyped,
    InvoiceToJSON,
    InvoiceToJSONTyped,
} from './Invoice';

/**
 * 
 * @export
 * @interface ListInvoicesResponse
 */
export interface ListInvoicesResponse {
    /**
     * 
     * @type {Array<Invoice>}
     * @memberof ListInvoicesResponse
     */
    data: Array<Invoice>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListInvoicesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListInvoicesResponse interface.
 */
export function instanceOfListInvoicesResponse(value: object): value is ListInvoicesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListInvoicesResponseFromJSON(json: any): ListInvoicesResponse {
    return ListInvoicesResponseFromJSONTyped(json, false);
}

export function ListInvoicesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListInvoicesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(InvoiceFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListInvoicesResponseToJSON(json: any): ListInvoicesResponse {
      return ListInvoicesResponseToJSONTyped(json, false);
  }

  export function ListInvoicesResponseToJSONTyped(value?: ListInvoicesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(InvoiceToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

