import { defineStore } from 'pinia';
import {
  AccessRolesPermissionPermissionEnum,
  AccessToken,
  ClockInPinLoginResponse,
  Employee,
} from '../sdk/v1';
import { clockingApi, employeeApi } from '../api.ts';
import { extractPermissionsFromAccessRoles } from '../lib/functions/permissionFunctions.ts';
import { redirect } from '../router/router.ts';
import { routes } from '../router/routes.ts';
import { decodeJwtToken } from '../lib/functions/authFunctions.ts';
import spacetime from 'spacetime';

interface PinAuthState {
  temporaryEmployeeToken: string | null;
  temporaryEmployeeTokenExpiry: number;
  temporaryEmployeeTimeout: null | ReturnType<typeof setTimeout>;
  employee: undefined | Employee;
}
export const usePinAuthStore = defineStore('pinAuth', {
  state: (): PinAuthState => ({
    temporaryEmployeeToken: null,
    temporaryEmployeeTokenExpiry: 0,
    temporaryEmployeeTimeout: null,
    employee: undefined,
  }),
  getters: {
    permissions(): AccessRolesPermissionPermissionEnum[] {
      return extractPermissionsFromAccessRoles(
        this.employee?.accessRoles || [],
      );
    },
  },
  actions: {
    logout(): void {
      this.clearEmployeeToken();
      this.employee = undefined;
    },
    clearEmployeeToken(): void {
      this.temporaryEmployeeToken = null;
      this.temporaryEmployeeTokenExpiry = 0;
      if (this.temporaryEmployeeTimeout) {
        clearTimeout(this.temporaryEmployeeTimeout);
      }
    },

    async login(pin: string): Promise<AccessToken> {
      return clockingApi
        .clockInPinLogin({
          clockInPinLoginRequest: {
            clockInPin: pin,
          },
        })
        .then(async ({ data }: ClockInPinLoginResponse) => {
          this.setEmployeeToken(data.token);
          return data;
        });
    },
    async fetchEmployee(): Promise<Employee> {
      return employeeApi
        .showCurrentEmployee({
          _with: ['accessRoles.permissions', 'photo'],
        })
        .then(({ data: employee }) => {
          this.employee = employee;
          return employee;
        });
    },

    setEmployeeToken(token: string): void {
      this.temporaryEmployeeToken = token;
      const tokenData = decodeJwtToken(token);
      this.temporaryEmployeeTokenExpiry = tokenData.exp;
      this.setTemporaryEmployeeTimeout();
    },
    setTemporaryEmployeeTimeout(): void {
      if (this.temporaryEmployeeTimeout) {
        clearTimeout(this.temporaryEmployeeTimeout);
      }
      const milliseconds = spacetime
        .now()
        .diff(
          spacetime(this.temporaryEmployeeTokenExpiry * 1000),
          'millisecond',
        );
      this.temporaryEmployeeTimeout = setTimeout(() => {
        redirect(routes.pinEntry.route()).then();
      }, milliseconds);
    },
  },
});
