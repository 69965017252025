/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Clock In Portal
 * @export
 * @interface ClockInPortal
 */
export interface ClockInPortal {
    /**
     * 
     * @type {number}
     * @memberof ClockInPortal
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ClockInPortal
     */
    name: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof ClockInPortal
     */
    locationIds: Array<number>;
}

/**
 * Check if a given object implements the ClockInPortal interface.
 */
export function instanceOfClockInPortal(value: object): value is ClockInPortal {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('locationIds' in value) || value['locationIds'] === undefined) return false;
    return true;
}

export function ClockInPortalFromJSON(json: any): ClockInPortal {
    return ClockInPortalFromJSONTyped(json, false);
}

export function ClockInPortalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockInPortal {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'locationIds': json['locationIds'],
    };
}

  export function ClockInPortalToJSON(json: any): ClockInPortal {
      return ClockInPortalToJSONTyped(json, false);
  }

  export function ClockInPortalToJSONTyped(value?: ClockInPortal | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'locationIds': value['locationIds'],
    };
}

