/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { EmployeeWithAvailability } from './EmployeeWithAvailability';
import {
    EmployeeWithAvailabilityFromJSON,
    EmployeeWithAvailabilityFromJSONTyped,
    EmployeeWithAvailabilityToJSON,
    EmployeeWithAvailabilityToJSONTyped,
} from './EmployeeWithAvailability';

/**
 * 
 * @export
 * @interface ListAvailableEmployeesResponse
 */
export interface ListAvailableEmployeesResponse {
    /**
     * 
     * @type {Array<EmployeeWithAvailability>}
     * @memberof ListAvailableEmployeesResponse
     */
    data: Array<EmployeeWithAvailability>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListAvailableEmployeesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListAvailableEmployeesResponse interface.
 */
export function instanceOfListAvailableEmployeesResponse(value: object): value is ListAvailableEmployeesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListAvailableEmployeesResponseFromJSON(json: any): ListAvailableEmployeesResponse {
    return ListAvailableEmployeesResponseFromJSONTyped(json, false);
}

export function ListAvailableEmployeesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListAvailableEmployeesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EmployeeWithAvailabilityFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListAvailableEmployeesResponseToJSON(json: any): ListAvailableEmployeesResponse {
      return ListAvailableEmployeesResponseToJSONTyped(json, false);
  }

  export function ListAvailableEmployeesResponseToJSONTyped(value?: ListAvailableEmployeesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(EmployeeWithAvailabilityToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

