/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyToJSONTyped,
} from './Company';

/**
 * 
 * @export
 * @interface Referral
 */
export interface Referral {
    /**
     * 
     * @type {number}
     * @memberof Referral
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    refereeRef: string;
    /**
     * 
     * @type {string}
     * @memberof Referral
     */
    refereeName: string;
    /**
     * 
     * @type {Date}
     * @memberof Referral
     */
    referredAt: Date;
    /**
     * 
     * @type {Company}
     * @memberof Referral
     */
    company?: Company;
}

/**
 * Check if a given object implements the Referral interface.
 */
export function instanceOfReferral(value: object): value is Referral {
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('refereeRef' in value) || value['refereeRef'] === undefined) return false;
    if (!('refereeName' in value) || value['refereeName'] === undefined) return false;
    if (!('referredAt' in value) || value['referredAt'] === undefined) return false;
    return true;
}

export function ReferralFromJSON(json: any): Referral {
    return ReferralFromJSONTyped(json, false);
}

export function ReferralFromJSONTyped(json: any, ignoreDiscriminator: boolean): Referral {
    if (json == null) {
        return json;
    }
    return {
        
        'companyId': json['companyId'],
        'refereeRef': json['refereeRef'],
        'refereeName': json['refereeName'],
        'referredAt': (new Date(json['referredAt'])),
        'company': json['company'] == null ? undefined : CompanyFromJSON(json['company']),
    };
}

  export function ReferralToJSON(json: any): Referral {
      return ReferralToJSONTyped(json, false);
  }

  export function ReferralToJSONTyped(value?: Referral | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'companyId': value['companyId'],
        'refereeRef': value['refereeRef'],
        'refereeName': value['refereeName'],
        'referredAt': ((value['referredAt']).toISOString()),
        'company': CompanyToJSON(value['company']),
    };
}

