/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeeImport } from './EmployeeImport';
import {
    EmployeeImportFromJSON,
    EmployeeImportFromJSONTyped,
    EmployeeImportToJSON,
    EmployeeImportToJSONTyped,
} from './EmployeeImport';

/**
 * 
 * @export
 * @interface CreateEmployeeImportResponse
 */
export interface CreateEmployeeImportResponse {
    /**
     * 
     * @type {EmployeeImport}
     * @memberof CreateEmployeeImportResponse
     */
    data: EmployeeImport;
}

/**
 * Check if a given object implements the CreateEmployeeImportResponse interface.
 */
export function instanceOfCreateEmployeeImportResponse(value: object): value is CreateEmployeeImportResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateEmployeeImportResponseFromJSON(json: any): CreateEmployeeImportResponse {
    return CreateEmployeeImportResponseFromJSONTyped(json, false);
}

export function CreateEmployeeImportResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmployeeImportResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeeImportFromJSON(json['data']),
    };
}

  export function CreateEmployeeImportResponseToJSON(json: any): CreateEmployeeImportResponse {
      return CreateEmployeeImportResponseToJSONTyped(json, false);
  }

  export function CreateEmployeeImportResponseToJSONTyped(value?: CreateEmployeeImportResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': EmployeeImportToJSON(value['data']),
    };
}

