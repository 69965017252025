import * as en from './lang/en.json';
import { createI18n } from 'vue-i18n';
import { getI18nDateTimeFormats } from './dateTimeFormats.ts';

const messages = {
  'en-gb': en,
};

export const supportedLocales = [{ id: 'en-gb', name: 'English (UK)' }];

export const i18n = createI18n({
  legacy: false,
  locale: 'en-gb',
  messages,
  datetimeFormats: Object.fromEntries(
    supportedLocales.map((s) => [s.id, getI18nDateTimeFormats()]),
  ),
});
