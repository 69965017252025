import {
  AccessRole,
  AccessRolesPermissionPermissionEnum,
  BillingPlanFeaturesEnum,
  Company,
} from '../../sdk/v1';
import { flattenArray } from '../../util/arrayFunctions.ts';
import { keyOfFirstTruthyValue } from '../../util/objectFunctions.ts';
import { PortalSetUpRestrictionEnum } from '../enums/PortalSetUpRestrictionEnum.ts';

export const Permission = AccessRolesPermissionPermissionEnum;
export type PermissionType = (typeof Permission)[keyof typeof Permission];

/**
 * Extract a *unique* set of permissions from a given list of access roles.
 */
export const extractPermissionsFromAccessRoles = (
  accessRoles: AccessRole[],
): AccessRolesPermissionPermissionEnum[] => {
  const permissions = flattenArray(
    accessRoles.map(
      (r) =>
        r.permissions
          ?.filter((p) => p.permission !== undefined)
          .map((p) => p.permission) || [],
    ),
  );
  // @ts-ignore: above filters undefined
  return [...new Set(permissions)];
};

export const restrictPortalSetUp = (
  company: Company,
  employeeAccessRoles: AccessRole[],
): PortalSetUpRestrictionEnum | undefined => {
  const companyHasBillingPlanFeature =
    company.latestSubscription.billingPlan.features.includes(
      BillingPlanFeaturesEnum.ClockInOut,
    );
  const employeeCanSetupPortals = extractPermissionsFromAccessRoles(
    employeeAccessRoles,
  ).includes(Permission.SetupClockInPortals);

  return keyOfFirstTruthyValue({
    [PortalSetUpRestrictionEnum.MissingEmployeePermission]:
      !employeeCanSetupPortals,
    [PortalSetUpRestrictionEnum.MissingBillingPlanFeature]:
      !companyHasBillingPlanFeature,
  });
};
