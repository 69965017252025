import { defineStore } from 'pinia';
import { ClockInPortal, Company, CompanyStatusEnum } from '../sdk/v1';
import { StorageEnum } from '../lib/enums/StorageEnum.ts';
import { redirect } from '../router/router.ts';
import { routes } from '../router/routes.ts';
import { LogoutReason } from '../lib/auth/logoutReason.ts';
import { clockInPortalApi, companyApi } from '../api.ts';

interface BootstrapState {
  company: undefined | Company;
  portal: undefined | ClockInPortal;
}

const abortBootstrap = (): void => {
  redirect(
    routes.logout.route({ reason: LogoutReason.MissingBootstrapData }),
  ).then();
};

export const useBootstrapStore = defineStore('bootstrap', {
  state: (): BootstrapState => ({
    company: undefined,
    portal: undefined,
  }),

  actions: {
    reset(): void {
      this.company = undefined;
      localStorage.removeItem(StorageEnum.CompanyId);
      this.portal = undefined;
      localStorage.removeItem(StorageEnum.PortalId);
    },
    async bootstrap(): Promise<void> {
      // Get companyId and portalId from local storage
      // If one is undefined or one doesn't exist for the logged-in user
      // - send to login page as an employee may have refreshed the page
      // Else setup the portal and save in state

      const companyId = localStorage.getItem(StorageEnum.CompanyId);
      const portalId = localStorage.getItem(StorageEnum.PortalId);
      if (!companyId || !portalId) {
        return abortBootstrap();
      }
      await companyApi
        .listCompaniesBelongingToUser()
        .then(({ data }) => {
          const company = data.find((c) => c.id === Number(companyId));
          if (!company || company.status !== CompanyStatusEnum.Active) {
            return abortBootstrap();
          }
          this.company = company;
        })
        .catch(() => {
          return abortBootstrap();
        });

      try {
        const portal = (
          await clockInPortalApi.showClockInPortal({ id: Number(portalId) })
        ).data;
        if (!portal) {
          return abortBootstrap();
        }
        this.portal = portal;
      } catch {
        return abortBootstrap();
      }
    },
    setCompany(company: Company | undefined): void {
      this.company = company;
      if (company) {
        localStorage.setItem(StorageEnum.CompanyId, String(company.id));
      } else {
        localStorage.removeItem(StorageEnum.CompanyId);
      }
    },
    setPortal(portal: ClockInPortal | undefined): void {
      this.portal = portal;
      if (portal) {
        localStorage.setItem(StorageEnum.PortalId, String(portal.id));
      } else {
        localStorage.removeItem(StorageEnum.PortalId);
      }
    },
  },
});
