/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateShiftAreaSessionData
 */
export interface CreateShiftAreaSessionData {
    /**
     * 
     * @type {number}
     * @memberof CreateShiftAreaSessionData
     */
    shiftAreaId: number;
    /**
     * 
     * @type {Date}
     * @memberof CreateShiftAreaSessionData
     */
    startTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateShiftAreaSessionData
     */
    endTime: Date;
}

/**
 * Check if a given object implements the CreateShiftAreaSessionData interface.
 */
export function instanceOfCreateShiftAreaSessionData(value: object): value is CreateShiftAreaSessionData {
    if (!('shiftAreaId' in value) || value['shiftAreaId'] === undefined) return false;
    if (!('startTime' in value) || value['startTime'] === undefined) return false;
    if (!('endTime' in value) || value['endTime'] === undefined) return false;
    return true;
}

export function CreateShiftAreaSessionDataFromJSON(json: any): CreateShiftAreaSessionData {
    return CreateShiftAreaSessionDataFromJSONTyped(json, false);
}

export function CreateShiftAreaSessionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateShiftAreaSessionData {
    if (json == null) {
        return json;
    }
    return {
        
        'shiftAreaId': json['shiftAreaId'],
        'startTime': (new Date(json['startTime'])),
        'endTime': (new Date(json['endTime'])),
    };
}

  export function CreateShiftAreaSessionDataToJSON(json: any): CreateShiftAreaSessionData {
      return CreateShiftAreaSessionDataToJSONTyped(json, false);
  }

  export function CreateShiftAreaSessionDataToJSONTyped(value?: CreateShiftAreaSessionData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'shiftAreaId': value['shiftAreaId'],
        'startTime': ((value['startTime']).toISOString()),
        'endTime': ((value['endTime']).toISOString()),
    };
}

