/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateScheduledBreakData } from './CreateScheduledBreakData';
import {
    CreateScheduledBreakDataFromJSON,
    CreateScheduledBreakDataFromJSONTyped,
    CreateScheduledBreakDataToJSON,
    CreateScheduledBreakDataToJSONTyped,
} from './CreateScheduledBreakData';

/**
 * A template for the shifts to be created
 * @export
 * @interface BulkCreateShiftDataBaseShiftData
 */
export interface BulkCreateShiftDataBaseShiftData {
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    scheduleId: number;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    jobRoleId: number;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    locationId: number;
    /**
     * 
     * @type {number}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    employeeId: number | null;
    /**
     * Whether to offer the shift to all eligible employees
     * @type {boolean}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    open?: boolean;
    /**
     * Whether the system can auto-assign employees when they respond to an open shift
     * @type {boolean}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    requiresApproval?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    startsAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    endsAt: Date;
    /**
     * Whether to show the shift end time to non-managerial staff
     * @type {boolean}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    showEndTime?: boolean;
    /**
     * Whether to show the job role to non-managerial staff
     * @type {boolean}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    showJobRole?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    tagIds?: Array<number> | null;
    /**
     * 
     * @type {Array<CreateScheduledBreakData>}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    scheduledBreaks?: Array<CreateScheduledBreakData>;
    /**
     * 
     * @type {string}
     * @memberof BulkCreateShiftDataBaseShiftData
     */
    notes?: string | null;
}

/**
 * Check if a given object implements the BulkCreateShiftDataBaseShiftData interface.
 */
export function instanceOfBulkCreateShiftDataBaseShiftData(value: object): value is BulkCreateShiftDataBaseShiftData {
    if (!('scheduleId' in value) || value['scheduleId'] === undefined) return false;
    if (!('jobRoleId' in value) || value['jobRoleId'] === undefined) return false;
    if (!('locationId' in value) || value['locationId'] === undefined) return false;
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('startsAt' in value) || value['startsAt'] === undefined) return false;
    if (!('endsAt' in value) || value['endsAt'] === undefined) return false;
    return true;
}

export function BulkCreateShiftDataBaseShiftDataFromJSON(json: any): BulkCreateShiftDataBaseShiftData {
    return BulkCreateShiftDataBaseShiftDataFromJSONTyped(json, false);
}

export function BulkCreateShiftDataBaseShiftDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkCreateShiftDataBaseShiftData {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduleId': json['scheduleId'],
        'jobRoleId': json['jobRoleId'],
        'locationId': json['locationId'],
        'employeeId': json['employeeId'],
        'open': json['open'] == null ? undefined : json['open'],
        'requiresApproval': json['requiresApproval'] == null ? undefined : json['requiresApproval'],
        'startsAt': (new Date(json['startsAt'])),
        'endsAt': (new Date(json['endsAt'])),
        'showEndTime': json['showEndTime'] == null ? undefined : json['showEndTime'],
        'showJobRole': json['showJobRole'] == null ? undefined : json['showJobRole'],
        'tagIds': json['tagIds'] == null ? undefined : json['tagIds'],
        'scheduledBreaks': json['scheduledBreaks'] == null ? undefined : ((json['scheduledBreaks'] as Array<any>).map(CreateScheduledBreakDataFromJSON)),
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

  export function BulkCreateShiftDataBaseShiftDataToJSON(json: any): BulkCreateShiftDataBaseShiftData {
      return BulkCreateShiftDataBaseShiftDataToJSONTyped(json, false);
  }

  export function BulkCreateShiftDataBaseShiftDataToJSONTyped(value?: BulkCreateShiftDataBaseShiftData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'scheduleId': value['scheduleId'],
        'jobRoleId': value['jobRoleId'],
        'locationId': value['locationId'],
        'employeeId': value['employeeId'],
        'open': value['open'],
        'requiresApproval': value['requiresApproval'],
        'startsAt': ((value['startsAt']).toISOString()),
        'endsAt': ((value['endsAt']).toISOString()),
        'showEndTime': value['showEndTime'],
        'showJobRole': value['showJobRole'],
        'tagIds': value['tagIds'],
        'scheduledBreaks': value['scheduledBreaks'] == null ? undefined : ((value['scheduledBreaks'] as Array<any>).map(CreateScheduledBreakDataToJSON)),
        'notes': value['notes'],
    };
}

