/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCompanyData,
  CreateCompanyResponse,
  ListCompaniesBelongingToUserResponse,
  ListCompanyNotificationChannelsResponse,
  RegisterCompanyData,
  RegisterCompanyResponse,
  ShowCompanyOnboardingResponse,
  ShowCompanyResponse,
  ShowEnabledFeaturesResponse,
  UpdateCompanyData,
  UpdateCompanyNotificationChannelData,
  UpdateCompanyNotificationChannelResponse,
  UpdateCompanyOnboardingData,
  UpdateCompanyOnboardingResponse,
  UpdateCompanyResponse,
  UpdateEnabledFeaturesResponse,
  UpdateFeaturesEnabledData,
} from '../models/index';
import {
    CreateCompanyDataFromJSON,
    CreateCompanyDataToJSON,
    CreateCompanyResponseFromJSON,
    CreateCompanyResponseToJSON,
    ListCompaniesBelongingToUserResponseFromJSON,
    ListCompaniesBelongingToUserResponseToJSON,
    ListCompanyNotificationChannelsResponseFromJSON,
    ListCompanyNotificationChannelsResponseToJSON,
    RegisterCompanyDataFromJSON,
    RegisterCompanyDataToJSON,
    RegisterCompanyResponseFromJSON,
    RegisterCompanyResponseToJSON,
    ShowCompanyOnboardingResponseFromJSON,
    ShowCompanyOnboardingResponseToJSON,
    ShowCompanyResponseFromJSON,
    ShowCompanyResponseToJSON,
    ShowEnabledFeaturesResponseFromJSON,
    ShowEnabledFeaturesResponseToJSON,
    UpdateCompanyDataFromJSON,
    UpdateCompanyDataToJSON,
    UpdateCompanyNotificationChannelDataFromJSON,
    UpdateCompanyNotificationChannelDataToJSON,
    UpdateCompanyNotificationChannelResponseFromJSON,
    UpdateCompanyNotificationChannelResponseToJSON,
    UpdateCompanyOnboardingDataFromJSON,
    UpdateCompanyOnboardingDataToJSON,
    UpdateCompanyOnboardingResponseFromJSON,
    UpdateCompanyOnboardingResponseToJSON,
    UpdateCompanyResponseFromJSON,
    UpdateCompanyResponseToJSON,
    UpdateEnabledFeaturesResponseFromJSON,
    UpdateEnabledFeaturesResponseToJSON,
    UpdateFeaturesEnabledDataFromJSON,
    UpdateFeaturesEnabledDataToJSON,
} from '../models/index';

export interface CreateCompanyRequest {
    _with?: Array<string>;
    createCompanyData?: CreateCompanyData;
}

export interface ListCompaniesBelongingToUserRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface RegisterCompanyRequest {
    registerCompanyData?: RegisterCompanyData;
}

export interface ShowCompanyRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateCompanyRequest {
    id: number;
    _with?: Array<string>;
    updateCompanyData?: UpdateCompanyData;
}

export interface UpdateCompanyNotificationChannelRequest {
    group: UpdateCompanyNotificationChannelGroupEnum;
    channel: UpdateCompanyNotificationChannelChannelEnum;
    updateCompanyNotificationChannelData?: UpdateCompanyNotificationChannelData;
}

export interface UpdateCompanyOnboardingRequest {
    updateCompanyOnboardingData?: UpdateCompanyOnboardingData;
}

export interface UpdateEnabledFeaturesRequest {
    updateFeaturesEnabledData?: UpdateFeaturesEnabledData;
}

/**
 * 
 */
export class CompanyApi extends runtime.BaseAPI {

    /**
     * Create an additional company for an existing user
     * Create Company
     */
    async createCompanyRaw(requestParameters: CreateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCompanyResponse>> {
        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyDataToJSON(requestParameters.createCompanyData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Create an additional company for an existing user
     * Create Company
     */
    async createCompany(requestParameters: CreateCompanyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCompanyResponse> {
        const response = await this.createCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Companies that the current user has access to
     * My Companies
     */
    async listCompaniesBelongingToUserRaw(requestParameters: ListCompaniesBelongingToUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListCompaniesBelongingToUserResponse>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.orderBy) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.perPage !== undefined) {
            queryParameters['perPage'] = requestParameters.perPage;
        }

        if (requestParameters.where !== undefined) {
            queryParameters['where'] = requestParameters.where;
        }

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/mine`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCompaniesBelongingToUserResponseFromJSON(jsonValue));
    }

    /**
     * Companies that the current user has access to
     * My Companies
     */
    async listCompaniesBelongingToUser(requestParameters: ListCompaniesBelongingToUserRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCompaniesBelongingToUserResponse> {
        const response = await this.listCompaniesBelongingToUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List your companies enabled notification channels.
     * List Company Notification Channels
     */
    async listCompanyNotificationChannelsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListCompanyNotificationChannelsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company-notification-channel.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/notification-channels`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCompanyNotificationChannelsResponseFromJSON(jsonValue));
    }

    /**
     * List your companies enabled notification channels.
     * List Company Notification Channels
     */
    async listCompanyNotificationChannels(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCompanyNotificationChannelsResponse> {
        const response = await this.listCompanyNotificationChannelsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Register a brand new company.
     * Register
     */
    async registerCompanyRaw(requestParameters: RegisterCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegisterCompanyResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/companies/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterCompanyDataToJSON(requestParameters.registerCompanyData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegisterCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Register a brand new company.
     * Register
     */
    async registerCompany(requestParameters: RegisterCompanyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegisterCompanyResponse> {
        const response = await this.registerCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show details of a specific company
     * Show Company
     */
    async showCompanyRaw(requestParameters: ShowCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowCompanyResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling showCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Show details of a specific company
     * Show Company
     */
    async showCompany(requestParameters: ShowCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowCompanyResponse> {
        const response = await this.showCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show the status of a companies onboarding progress
     * Show Company Onboarding
     */
    async showCompanyOnboardingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowCompanyOnboardingResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company-onboarding.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowCompanyOnboardingResponseFromJSON(jsonValue));
    }

    /**
     * Show the status of a companies onboarding progress
     * Show Company Onboarding
     */
    async showCompanyOnboarding(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowCompanyOnboardingResponse> {
        const response = await this.showCompanyOnboardingRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show enabled features for the current company.
     * Show Enabled Features
     */
    async showEnabledFeaturesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowEnabledFeaturesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company-features.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/features`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowEnabledFeaturesResponseFromJSON(jsonValue));
    }

    /**
     * Show enabled features for the current company.
     * Show Enabled Features
     */
    async showEnabledFeatures(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowEnabledFeaturesResponse> {
        const response = await this.showEnabledFeaturesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update the details of a specific company
     * Update Company
     */
    async updateCompanyRaw(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCompanyResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCompany.');
        }

        const queryParameters: any = {};

        if (requestParameters._with) {
            queryParameters['with'] = requestParameters._with;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/companies/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyDataToJSON(requestParameters.updateCompanyData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCompanyResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of a specific company
     * Update Company
     */
    async updateCompany(requestParameters: UpdateCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCompanyResponse> {
        const response = await this.updateCompanyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Toggle a notification channel for a given group.
     * Update Company Notification Channel
     */
    async updateCompanyNotificationChannelRaw(requestParameters: UpdateCompanyNotificationChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCompanyNotificationChannelResponse>> {
        if (requestParameters.group === null || requestParameters.group === undefined) {
            throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling updateCompanyNotificationChannel.');
        }

        if (requestParameters.channel === null || requestParameters.channel === undefined) {
            throw new runtime.RequiredError('channel','Required parameter requestParameters.channel was null or undefined when calling updateCompanyNotificationChannel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company-notification-channel.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/notification-channels/{group}/{channel}`.replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))).replace(`{${"channel"}}`, encodeURIComponent(String(requestParameters.channel))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyNotificationChannelDataToJSON(requestParameters.updateCompanyNotificationChannelData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCompanyNotificationChannelResponseFromJSON(jsonValue));
    }

    /**
     * Toggle a notification channel for a given group.
     * Update Company Notification Channel
     */
    async updateCompanyNotificationChannel(requestParameters: UpdateCompanyNotificationChannelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCompanyNotificationChannelResponse> {
        const response = await this.updateCompanyNotificationChannelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Manually update the progress of your company\'s onboarding.
     * Update Company Onboarding
     */
    async updateCompanyOnboardingRaw(requestParameters: UpdateCompanyOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateCompanyOnboardingResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/onboarding`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyOnboardingDataToJSON(requestParameters.updateCompanyOnboardingData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateCompanyOnboardingResponseFromJSON(jsonValue));
    }

    /**
     * Manually update the progress of your company\'s onboarding.
     * Update Company Onboarding
     */
    async updateCompanyOnboarding(requestParameters: UpdateCompanyOnboardingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateCompanyOnboardingResponse> {
        const response = await this.updateCompanyOnboardingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update enabled features for the current company.
     * Update Enabled Features
     */
    async updateEnabledFeaturesRaw(requestParameters: UpdateEnabledFeaturesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateEnabledFeaturesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.company-features.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/company/features`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateFeaturesEnabledDataToJSON(requestParameters.updateFeaturesEnabledData),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateEnabledFeaturesResponseFromJSON(jsonValue));
    }

    /**
     * Update enabled features for the current company.
     * Update Enabled Features
     */
    async updateEnabledFeatures(requestParameters: UpdateEnabledFeaturesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateEnabledFeaturesResponse> {
        const response = await this.updateEnabledFeaturesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
  * @export
  * @enum {string}
  */
export enum UpdateCompanyNotificationChannelGroupEnum {
    Absence = 'Absence',
    Billing = 'Billing',
    Document = 'Document',
    Leave = 'Leave',
    Shift = 'Shift',
    Timesheet = 'Timesheet'
}
/**
  * @export
  * @enum {string}
  */
export enum UpdateCompanyNotificationChannelChannelEnum {
    Email = 'Email',
    Push = 'Push',
    Sms = 'Sms'
}
