/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SendEmployeeInviteData
 */
export interface SendEmployeeInviteData {
    /**
     * 
     * @type {string}
     * @memberof SendEmployeeInviteData
     */
    newEmail?: string;
}

/**
 * Check if a given object implements the SendEmployeeInviteData interface.
 */
export function instanceOfSendEmployeeInviteData(value: object): value is SendEmployeeInviteData {
    return true;
}

export function SendEmployeeInviteDataFromJSON(json: any): SendEmployeeInviteData {
    return SendEmployeeInviteDataFromJSONTyped(json, false);
}

export function SendEmployeeInviteDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): SendEmployeeInviteData {
    if (json == null) {
        return json;
    }
    return {
        
        'newEmail': json['newEmail'] == null ? undefined : json['newEmail'],
    };
}

  export function SendEmployeeInviteDataToJSON(json: any): SendEmployeeInviteData {
      return SendEmployeeInviteDataToJSONTyped(json, false);
  }

  export function SendEmployeeInviteDataToJSONTyped(value?: SendEmployeeInviteData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'newEmail': value['newEmail'],
    };
}

