/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Represents a single file (of various types) that has been uploaded by a user
 * @export
 * @interface Upload
 */
export interface Upload {
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    path: string;
    /**
     * The name of the file when it was originally uploaded. Should only be used for display purposes.
     * @type {string}
     * @memberof Upload
     */
    originalFilename: string | null;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    purpose: UploadPurposeEnum;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    status: UploadStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Upload
     */
    mimeType: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Upload
     */
    isSecure: boolean;
    /**
     * 
     * @type {number}
     * @memberof Upload
     */
    fileSizeInBytes: number;
    /**
     * A full url to access the upload. Only valid for 2 minutes
     * @type {string}
     * @memberof Upload
     */
    url: string;
    /**
     * 
     * @type {Date}
     * @memberof Upload
     */
    createdAt: Date;
}

/**
* @export
* @enum {string}
*/
export enum UploadPurposeEnum {
    Calendar = 'Calendar',
    CompanyLogo = 'CompanyLogo',
    Document = 'Document',
    EmployeeImport = 'EmployeeImport',
    EmployeePhoto = 'EmployeePhoto',
    Invoices = 'Invoices'
}
/**
* @export
* @enum {string}
*/
export enum UploadStatusEnum {
    Complete = 'Complete',
    Pending = 'Pending'
}


/**
 * Check if a given object implements the Upload interface.
 */
export function instanceOfUpload(value: object): value is Upload {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('path' in value) || value['path'] === undefined) return false;
    if (!('originalFilename' in value) || value['originalFilename'] === undefined) return false;
    if (!('purpose' in value) || value['purpose'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('mimeType' in value) || value['mimeType'] === undefined) return false;
    if (!('isSecure' in value) || value['isSecure'] === undefined) return false;
    if (!('fileSizeInBytes' in value) || value['fileSizeInBytes'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    return true;
}

export function UploadFromJSON(json: any): Upload {
    return UploadFromJSONTyped(json, false);
}

export function UploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): Upload {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'path': json['path'],
        'originalFilename': json['originalFilename'],
        'purpose': json['purpose'],
        'status': json['status'],
        'mimeType': json['mimeType'],
        'isSecure': json['isSecure'],
        'fileSizeInBytes': json['fileSizeInBytes'],
        'url': json['url'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

  export function UploadToJSON(json: any): Upload {
      return UploadToJSONTyped(json, false);
  }

  export function UploadToJSONTyped(value?: Upload | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'path': value['path'],
        'originalFilename': value['originalFilename'],
        'purpose': value['purpose'],
        'status': value['status'],
        'mimeType': value['mimeType'],
        'isSecure': value['isSecure'],
        'fileSizeInBytes': value['fileSizeInBytes'],
        'url': value['url'],
        'createdAt': ((value['createdAt']).toISOString()),
    };
}

