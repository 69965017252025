/**
 * This file is purely used for and by the generated API SDK code. Please don't use it internally.
 * This file gets copied into the 'api/' directory as part of the 'generate-sdk' command.
 * This is because currently the 'typescript-fetch' generator doesn't support 'import mappings' yet,
 * (@see https://github.com/OpenAPITools/openapi-generator/issues/13273)
 * which would otherwise allow us to reference lib code directly.
 */
import { PlainDate } from '../../../lib/date-time/PlainDate';

export class ShiftiePlainDate extends PlainDate {}

export const ShiftiePlainDateFromJSON = (value: any): PlainDate => {
  if (value === undefined || value === null || value instanceof PlainDate) {
    return value;
  }
  return PlainDate.from(value);
};

export const ShiftiePlainDateFromJSONTyped = ShiftiePlainDateFromJSON;

export const ShiftiePlainDateToJSON = (
  value?: PlainDate | null | undefined,
): undefined | null | string => {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return value.toJSON();
};
