import './main.scss';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import App from './App.vue';
import router from './router/router.ts';
import Vue3Toastify from 'vue3-toastify';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createVuetify } from 'vuetify';
import { i18n } from './i18n';
import { aliases, fa } from 'vuetify/iconsets/fa';
import * as Sentry from '@sentry/vue';
import { isTruthy } from './lib/helpers/booleans.ts';
import { initialiseClarity } from './plugins/clarity.ts';
import VueGtag from 'vue-gtag';

const pinia = createPinia();
const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'fa',
    aliases,
    sets: {
      fa,
    },
  },
});

const app = createApp(App);
app.use(pinia);
app.use(vuetify);
app.use(router);
app.use(Vue3Toastify);
app.use(i18n);

// Initialise Sentry
Sentry.init({
  app,
  dsn: 'https://e2fb5f177991db8e054586bd9aeee9cc@o454135.ingest.sentry.io/4506042370228224',
  enabled: isTruthy(import.meta.env.VITE_SENTRY_ENABLED),

  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: import.meta.env.VITE_SENTRY_TRACE_SAMPLE_RATE,
  // Session Replay
  replaysSessionSampleRate: import.meta.env.VITE_SENTRY_RP_SESSION_RATE,
  replaysOnErrorSampleRate: import.meta.env.VITE_SENTRY_RP_SESSION_ERROR_RATE,
});

// Initialise Google Analytics
app.use(VueGtag, {
  enabled: isTruthy(import.meta.env.VITE_GA_ENABLED),
  config: {
    id: import.meta.env.VITE_GA_MEASUREMENT_ID,
  },
});

// Initialise Clarity
initialiseClarity();

// Mount the application
app.mount('#app');
